import React, { useRef, useState } from 'react';
import fsLogo from '../../images/fs-logo.svg';
import msLogo from '../../images/microsoft-logo.svg'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { Link } from 'react-router-dom';
import { login } from '../../api/account';
import { AddinLoginRequest, SSODetail } from '../../requests/AccountRequests';
import Auth from '../../model/Auth';
import Confirm from '../common/Confirm';
import { isSuccess } from '../../constants/Status';
import { getSubscription } from '../../api/subscription';
import Session from '../../model/Session';
import { setSessionCookie } from '../../util/SessionUtil';
import { waitForLoad } from '../../util/Loader';



const Login = (props) => {
    const { instance } = useMsal();
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordExpired, setPasswordExpired] = useState(false);
    const confirmElement = useRef();
    const loginSSO = () => {
        try {
            console.log('loginSSO');
            setLoading(true);
            const loginResponse = instance.loginPopup(loginRequest).then(loginResponse => {
                debugger;
                const { tokenType, accessToken, expiresOn, scopes, refreshToken, idToken } = loginResponse;
                let ssoDetail = SSODetail(tokenType, accessToken, tokenType, expiresOn, 'email', refreshToken, idToken);
                let addinLoginRequest = AddinLoginRequest(loginResponse.account.username, null, ssoDetail);

                login(addinLoginRequest)
                    .then(response => {

                        if (isSuccess(response.status)) {
                            loginCallback(response, loginResponse.account.username);
                        }
                        else {
                            setLoading(false, () => {
                                getDialog().status(response.data.message);
                            });

                        }

                    })
                    .catch(function (error) {
                        setLoading(false, () => {
                            getDialog().status(error.message);
                        });

                    });

            }).catch(function (error) {
                setLoading(false, () => {
                    getDialog().status(error.message);
                });

            });

        } catch (error) {
            setLoading(false);
            getDialog().status(error.message);
        }
    }

    const loginCallback = (response, userName) => {
        const { token, token_id } = response.headers;
        const isPasswordExpired = (response && response.data) ? response.data.passwordExpired : false;
        const auth = new Auth(userName, token_id, token, null, isPasswordExpired);
        const session = Session.getInstance(auth);
        setSessionCookie(session);
        debugger;
        getSubscription(userName, token_id, token).then(subscriptionDetailResponse => {
            if (isSuccess(subscriptionDetailResponse.status))
                auth.subscriptionDetailResponse = subscriptionDetailResponse;
            else {
                setLoading(false);
                setLoading((state) => {
                    getDialog().status(subscriptionDetailResponse.data.message);
                    return state;
                });

            }
            props.setAppState(auth);
        }).catch(e => {
            debugger;
            setLoading(false);
            setLoading((state) => {
                getDialog().status(e.message);
                return state;
            });
        });
    }

    const getDialog = () => {
        return (confirmElement.current);
    }
    const validateLoginForm = () => {
        if (!emailAddress) {
            getDialog().status('Please enter email address');
            return false;
        }
        if (!password) {
            getDialog().status('Please enter password');
            return false;
        }
        return true;
    }
    const seqncLogin = () => {
        console.log('seqncLogin');
        if (!validateLoginForm())
            return;
        setLoading(true);
        let addinLoginRequest = AddinLoginRequest(emailAddress, password, null);
        login(addinLoginRequest)
            .then((response) => {
                if (response === null || response === undefined) {
                    setLoading(false);
                    setLoading((state) => {
                        getDialog().status('Service might be down. Please try again after some time');
                        return state;
                    });
                }
                else if (isSuccess(response.status))
                    loginCallback(response, emailAddress);
                else {
                    setLoading(false);
                    {
                        setLoading((state) => {
                            getDialog().status(response.data.message);
                            return state;
                        });
                    }

                }
            })
            .catch(function (error) {
                setLoading(false);
                setLoading((state) => {
                    getDialog().status(error.message);
                    return state;
                });

            });
    }


    const renderFn = () => {
        return (
            <React.Fragment>
                <div id="loginDiv" >
                    <div className="mb-4"><img src={fsLogo} style={{ height: '108px', width: '268px', marginTop: '-21px', marginBottom: '-29px' }} /></div>

                    <div><button type="button" className="btn btn-outline-dark" id="loginWithMS" onClick={() => loginSSO()}><img type="image/svg+xml" src={msLogo} style={{ marginRight: '12px' }} />Login with Microsoft</button></div>
                    <div className="a-divider a-divider-break"><h5>OR</h5></div>
                    <form onSubmit={(e) => { e.preventDefault(); }}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Username</label>
                            <input type="email"
                                className="form-control"
                                style={{ textTransform: 'lowercase' }}
                                id="userName"
                                placeholder="Username entered at registration"
                                value={emailAddress}
                                onChange={(e) => { setEmailAddress(e.target.value) }}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password</label><Link to="/forgot-password" className="blueLink" id="passwordHelp">Need password help?</Link>
                            <input type="password"
                                className="form-control"
                                id="password"
                                placeholder="FormulaSuite password (not Microsoft or other)"
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                        </div>
                        <div><button className="btn btn-primary" id="login" style={{ backgroundColor: '#660066', fontWeight: 'bold', borderColor: '#660066' }} onClick={seqncLogin}>Login</button></div>
                    </form>
                    <div className="mt-2">
                        <label style={{ fontWeight: 'bold', color: '#6e6d6d' }}>New to FormulaSuite?</label><Link to="/signup" className="blueLink" style={{ fontWeight: 'bold', marginLeft: '11px' }}>Start 14-day Trial</Link>
                    </div>

                </div>
                <Confirm show={false} ref={confirmElement} />
            </React.Fragment>


        );
    }

    return waitForLoad(loading, renderFn);


}

export default Login;