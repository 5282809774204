import React from 'react';
import { Redirect, withRouter } from 'react-router';
import Confirm from '../common/Confirm';
import PropTypes from "prop-types";
import AuthContext from '../../contexts/AuthContext';
import SubscriptionDetails from '../../model/SubscriptionDetails';
import UIBillingInfo from '../../model/UIBillingInfo';
import { fetchBillingDetails, update, updateBillings, removeBillingInfo } from '../../api/subscription';
import { waitForLoad } from '../../util/Loader';
import { isValidField, addValidateClass } from '../../util/Util';
import { countryOptions } from '../../util/Countries';
import { usStatesOptions } from '../../util/Countries';
import { isSuccess } from '../../constants/Status';
import { Messages } from '../../constants/Messages';
class BillingDetails extends React.Component {
    //TODO need to replace hardcoded year dropdown
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    static messages = {
        ADD_NEW_CARD_VALUE: "ADD_NEW_CARD_VALUE",
        ADD_NEW_CARD_NAME: "Add new card",
    }
    constructor(props) {
        super(props);

        this.state = {
            showStateDdl: true,
            isValidFirstName: true,
            hasOffice365: false,
            //billing details
            firstName: null,
            lastName: null,
            company: null,
            email: null,
            address1: null,
            address2: null,
            country: 'US',
            city: null,
            state: 'AL',
            zip: null,
            //card details
            card: 'ADD_NEW_CARD_VALUE',
            cardType: null,
            year: 2022,
            month: 1,
            firstSix: null,
            lastFour: null,
            number: null,
            verificationValue: null,
            isLoading: true,
            validateForm: false,
            uiBillingInfo: null
        }
        const self = this;
        this.updateBilling = props.updateBilling;
        this.confirmElement = React.createRef();
        const accData = this.props.accountManagementData();
        this.isValidState = this.updateBilling || (accData && (accData.selectedPlan || accData.selectedSeats));

    }

    componentDidMount = () => {
        this.getBillingDetails();
    }

    getBillingDetails = () => {
        fetchBillingDetails(this.context).
            then(response => {
                this.setFormData(response.data);
            }).
            catch(e => {
                //this.getDialog().status(e.response.message);
            });
    }
    /*****************Util functions *****************************/
    isUseExistingCard = () => {
        return this.lastFour && this.card === this.getMessages().ADD_NEW_CARD_VALUE
    }
    setFormData = (data) => {

        const uiBillingInfo = data.billingInfo;
        if (uiBillingInfo)
            this.setState({
                firstName: uiBillingInfo.firstName,
                lastName: uiBillingInfo.lastName,
                company: uiBillingInfo.company,
                email: uiBillingInfo.email,
                address1: uiBillingInfo.address1,
                address2: uiBillingInfo.address2,
                country: uiBillingInfo.country,
                city: uiBillingInfo.city,
                state: uiBillingInfo.state,
                zip: uiBillingInfo.zip,
                number: uiBillingInfo.number,
                month: uiBillingInfo.month ? uiBillingInfo.month : 1,
                year: uiBillingInfo.year ? uiBillingInfo.year : 2022,
                verificationValue: uiBillingInfo.verificationValue,
                lastFour: uiBillingInfo.lastFour,
                card: uiBillingInfo.lastFour ? uiBillingInfo.lastFour : this.getMessages().ADD_NEW_CARD_VALUE,
                uiBillingInfo: uiBillingInfo,
                isLoading: false

            });
        else this.setState({ isLoading: false });
    }
    getDialog = () => {
        return (this.confirmElement.current);
    }
    toPurchaseSummary = () => {
        this.props.history.goBack();
    }

    toAccountManagement = () => {
        this.props.history.push(`${this.props.accPath}/manageSubscription`)
    }

    getMessages = () => {
        return BillingDetails.messages
    }

    getStateControl = () => {
        if (this.state.showStateDdl)
            return (
                <div className="col-md-6" id="stateDdlDiv">
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label-sm required-label" htmlFor="state">State</label>
                        <div className="col-lg-9">
                            <select className={`form-control form-control-sm ${addValidateClass(this.state.state, this.state.validateForm)}`}
                                id="stateDdl"
                                value={this.state.state}
                                onChange={(e) => { this.setState({ state: e.target.value }) }}>
                                {usStatesOptions}
                            </select>
                        </div>
                    </div>
                </div>
            );
        else {
            return (
                <div className="col-md-6" id="stateInputDiv" >
                    <div className="form-group row">
                        <label className="col-lg-3 col-form-label-sm required-label" htmlFor="state">State</label>
                        <div className="col-lg-9">
                            <input className={`form-control form-control-sm ${addValidateClass(this.state.state, this.state.validateForm)}`}
                                id="state"
                                value={this.state.state}
                                onChange={(e) => { this.setState({ state: e.target.value }) }} />
                        </div>
                    </div>
                </div>
            );
        }


    }

    /*****************End of Util functions *****************************/
    /******************Event handlers************************************/
    clearBillingInfo = () => {
        this.getDialog().prompt(Messages.DELETE_CONFIRMATION_MSG, () => {
            this.setState({ isLoading: true });

            removeBillingInfo(this.context)
                .then((response) => {
                    this.getBillingDetails();
                })
                .catch((e) => {
                    this.getDialog().status(e.message);
                });
        });
    }

    updateBillingDetails = () => {
        this.getDialog().prompt(Messages.SAVE_CONFIRMATION_MSG, () => {

            if (this.validateBillingAndCard()) {
                debugger;
                let updateBillingReq = {};
                updateBillingReq.emailId = this.context.user;
                const { firstName, lastName, company, email, address1, address2, city, state, zip, country, number, month, year, verificationValue } = this.state;
                const billingInfo = new UIBillingInfo(firstName, lastName, company, email, address1, address2, country, city, state, zip, null, year, month, number, verificationValue);
                updateBillingReq.uiBillingInfo = billingInfo;
                this.setState({ isLoading: true });
                updateBillings(this.context, updateBillingReq)
                    .then((response) => {

                        this.setState({ isLoading: false });
                        if (!isSuccess(response.status)) {
                            this.getDialog().status(response.data.message);
                        }
                    })
                    .catch(e => {
                        this.getDialog().status(e.response.message);
                    })
            }
        })


    }

    countryChanged = (country) => {
        const showStateDdl = country === 'US';
        const state = country === 'US' ? 'AL' : null;
        this.setState({ country: country, state: state, showStateDdl: showStateDdl });
    }

    selectCardChanged = (card) => {

        this.setState({
            number: null,
            month: 1,
            year: 2022,
            verificationValue: null,
            card: card
        });

    }
    /******************End of Event handlers************************************/

    validateBillingAndCard = () => {
        debugger;
        if (!this.state.hasOffice365 && !this.props.updateBilling) {
            this.getDialog().status('Please confirm that you have a valid Office 365 subscription by selecting checkbox');
            return false;
        }
        let reqFieldsPresent = true;
        if (!isValidField(this.state.firstName)) {
            reqFieldsPresent = false;
        }
        if (!isValidField(this.state.lastName)) {
            reqFieldsPresent = false;
        }

        if (!isValidField(this.state.address1)) {
            reqFieldsPresent = false;
        }

        if (!isValidField(this.state.country)) {
            reqFieldsPresent = false;
        }
        if (!isValidField(this.state.city)) {
            reqFieldsPresent = false;
        }
        if (!isValidField(this.state.state)) {
            reqFieldsPresent = false;
        }
        if (!isValidField(this.state.zip)) {
            reqFieldsPresent = false;
        }
        if (this.state.card == this.getMessages().ADD_NEW_CARD_VALUE) {
            if (!isValidField(this.state.number)) {
                reqFieldsPresent = false;
            }
            if (!isValidField(this.state.month)) {
                reqFieldsPresent = false;
            }
            if (!isValidField(this.state.year)) {
                reqFieldsPresent = false;
            }
            if (!isValidField(this.state.verificationValue)) {
                reqFieldsPresent = false;
            }
        }

        if (!reqFieldsPresent) {
            this.getDialog().status('Please provide values for all the required fields');
            this.setState({ validateForm: true });
        } else {
            if (this.state.validateForm)
                this.setState({ validateForm: false });
        }

        return reqFieldsPresent;

    }

    completePayment = () => {

        if (this.validateBillingAndCard()) {
            const auth = this.context;
            const accountManagementData = this.props.accountManagementData();
            const { uiAccountSubscriptionInfo, selectedPlan, selectedSeats } = accountManagementData;
            const uiBillingInfo = new UIBillingInfo(
                this.state.firstName,
                this.state.lastName,
                null,
                this.state.email,
                this.state.address1,
                this.state.address2,
                this.state.country,
                this.state.city,
                this.state.state,
                this.state.zip,
                this.state.cardType,
                this.state.year,
                this.state.month,
                this.state.number,
                this.state.verificationValue,
                null,
                null
            );
            const subscriptionDetails = new SubscriptionDetails(
                auth.user,
                selectedPlan,
                null,
                true,
                selectedSeats,
                this.isUseExistingCard() ? null : uiBillingInfo,
                accountManagementData.coupon,
                null
            );
            this.setState({ isLoading: true });

            update(auth, subscriptionDetails)
                .then(response => {

                    this.setState({ isLoading: false }, () => {
                        if (isSuccess(response.status)) {
                            this.getDialog().status('Your subscription plan has been updated',
                                () => {
                                    this.props.history.push(`${this.props.accPath}/manageSubscription`);
                                    const auth = this.context;
                                    auth.subscriptionDetailResponse = null;
                                    this.props.setAppState({ ...auth });
                                });
                        } else {
                            this.getDialog().status(response.data.message);
                        }

                    });

                }).
                catch(error => {
                    this.setState({ isLoading: false }, () => {
                        this.getDialog().status(error.message);
                    });

                });
        }

    }


    render() {

        if (this.isValidState)
            return waitForLoad(this.state.isLoading, this.renderFn);
        else
            return <Redirect to={`${this.props.accPath}/manageSubscription`} />
    }

    renderFn = () => {
        const accountManagementData = this.props.accountManagementData();
        const { validateForm } = this.state;
        return (
            <React.Fragment>
                {
                    this.props.updateBilling ? null : (
                        <div id="panel-title">
                            Billing &amp; Payment details
                        </div>
                    )}

                <div className="billing-scroller scrolling-container">
                    <span className="billing-section-label d-inline-block">Billing details</span>
                    <div id="billingDetailsDiv" className="overflow-hidden">
                        <form className="w-100 mt-2 mb-0 container-fluid" id="billing-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm required-label"
                                            htmlFor="firstName">First name</label>
                                        <div className="col-lg-9">
                                            <input
                                                className={`form-control form-control-sm ${addValidateClass(this.state.firstName, validateForm)}`}
                                                id="firstName"
                                                value={this.state.firstName}
                                                onChange={(e) => { this.setState({ firstName: e.target.value }) }}
                                                aria-describedby="validationServer03Feedback" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label
                                            className="col-lg-3 col-form-label-sm required-label"
                                            htmlFor="lastName">Last name
                                        </label>
                                        <div className="col-lg-9">
                                            <input
                                                className={`form-control form-control-sm ${addValidateClass(this.state.lastName, validateForm)}`}
                                                id="lastName"
                                                value={this.state.lastName}
                                                onChange={(e) => { this.setState({ lastName: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm" htmlFor="company">Company</label>
                                        <div className="col-lg-9">
                                            <input className="form-control form-control-sm "
                                                id="company"
                                                value={this.state.company}
                                                onChange={(e) => { this.setState({ company: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm" htmlFor="email">Email</label>
                                        <div className="col-lg-9">
                                            <input
                                                className="form-control form-control-sm "
                                                id="email"
                                                value={this.state.email}
                                                onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm required-label" htmlFor="address1">Address1</label>
                                        <div className="col-lg-9">
                                            <input className={`form-control form-control-sm ${addValidateClass(this.state.address1, validateForm)}`}
                                                id="address1"
                                                value={this.state.address1}
                                                onChange={(e) => { this.setState({ address1: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm" htmlFor="address2">Address2</label>
                                        <div className="col-lg-9">
                                            <input
                                                className={`form-control form-control-sm`}
                                                id="address2"
                                                value={this.state.address2}
                                                onChange={(e) => { this.setState({ address2: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm required-label" htmlFor="city">City</label>
                                        <div className="col-lg-9">
                                            <input
                                                className={`form-control form-control-sm ${addValidateClass(this.state.city, validateForm)}`}
                                                id="city"
                                                value={this.state.city}
                                                onChange={(e) => { this.setState({ city: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                                {this.getStateControl()}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm required-label" htmlFor="zip">Zip</label>
                                        <div className="col-lg-9">
                                            <input
                                                className={`form-control form-control-sm ${addValidateClass(this.state.zip, validateForm)}`}
                                                id="zip"
                                                value={this.state.zip}
                                                onChange={(e) => { this.setState({ zip: e.target.value }) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label-sm required-label" htmlFor="country">Country</label>
                                        <div className="col-lg-9">
                                            <select
                                                className={`form-control form-control-sm ${addValidateClass(this.state.country, validateForm)}`}
                                                id="country" value={this.state.country}
                                                onChange={(e) => { this.countryChanged(e.target.value) }}>
                                                {countryOptions}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <span className="billing-section-label d-inline-block">Card details</span>
                    <div id="cardDetailsDiv" >
                        <form className="container-fluid mt-2" id="card-detail-form">
                            <div className="row">
                                <div
                                    className={`col-md-5 ${this.state.lastFour ? null : 'd-none'}`} id="pick-cards-section">
                                    <div className="form-group row">
                                        <label className="col-md-4 col-form-label-sm "
                                            htmlFor="selectCard">Existing cards</label>
                                        <div className="col-md-8">
                                            <select id="selectCard"
                                                type="text"
                                                className={'form-control form-control-sm'}
                                                style={{ width: '150px' }}
                                                onChange={(e) => { this.selectCardChanged(e.target.value) }}
                                                value={this.state.card}
                                            >
                                                <option value={this.getMessages().ADD_NEW_CARD_VALUE}>Add a new card</option>
                                                {
                                                    this.state.lastFour ?
                                                        (<option value={this.state.lastFour}>x-{this.state.lastFour}</option>) :
                                                        null
                                                }


                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`col-md-7 ${this.state.card != this.getMessages().ADD_NEW_CARD_VALUE ? 'd-none' : null}`}
                                    id="add-new-card-section">
                                    <div className="row form-group">

                                        <label className="col-md-4 col-form-label-sm required-label"
                                            htmlFor="cardNumber">Card number</label>
                                        <div className="col-md-8">
                                            <input id="cardNumber"
                                                type="text"
                                                className={`form-control form-control-sm ${addValidateClass(this.state.number, validateForm)}`}
                                                style={{ width: '200px' }}
                                                value={this.state.number}
                                                onChange={(e) => { this.setState({ number: e.target.value }) }} /></div>


                                    </div>
                                    <div className="row form-group">
                                        <label className="col-md-4 col-form-label-sm required-label" htmlFor="cardNumber">Card Expiry Date</label>
                                        <div className="col-md-8">
                                            <select
                                                id="card-expiry-month"
                                                className="d-inline form-control form-control-sm"
                                                style={{ width: '45px !important' }}
                                                value={this.state.month}
                                                onChange={(e) => { this.setState({ month: e.target.value }) }}>
                                                <option value={1}>01</option>
                                                <option value={2}>02</option>
                                                <option value={3}>03</option>
                                                <option value={4}>04</option>
                                                <option value={5}>05</option>
                                                <option value={6}>06</option>
                                                <option value={7}>07</option>
                                                <option value={8}>08</option>
                                                <option value={9}>09</option>
                                                <option value={10}>10</option>
                                                <option value={11}>11</option>
                                                <option value={12}>12</option>
                                            </select>
                                            <span className="card-expiry-seperator d-inline mx-1" style={{ width: '10px' }}>/</span>
                                            <select id="card-expiry-year"
                                                className="d-inline form-control form-control-sm"
                                                sq-default-value style={{ width: '59px' }}
                                                value={this.state.year}
                                                onChange={(e) => { this.setState({ year: e.target.value }) }}
                                            >

                                                <option value={2022}>2022</option>
                                                <option value={2023}>2023</option>
                                                <option value={2024}>2024</option>
                                                <option value={2025}>2025</option>
                                                <option value={2026}>2026</option>
                                                <option value={2027}>2027</option>
                                                <option value={2028}>2028</option>
                                                <option value={2029}>2029</option>
                                                <option value={2030}>2030</option>
                                                <option value={2031}>2031</option>
                                                <option value={2032}>2032</option>
                                                <option value={2033}>2033</option>
                                                <option value={2034}>2034</option>
                                                <option value={2035}>2035</option>
                                                <option value={2036}>2036</option>
                                                <option value={2037}>2037</option>
                                                <option value={2038}>2038</option>
                                                <option value={2039}>2039</option>
                                                <option value={2040}>2040</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-4 col-form-label-sm required-label" htmlFor="cvv">CVV/CVC</label>
                                        <div className="col-md-8">
                                            <input type="text"
                                                className={`form-control form-control-sm ${addValidateClass(this.state.verificationValue, validateForm)}`}
                                                style={{ width: 67 }}
                                                id="cvv"
                                                value={this.state.verificationValue}
                                                onChange={(e) => { this.setState({ verificationValue: e.target.value }) }}
                                            /></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                {!this.props.updateBilling ?
                    (<>
                        <div id="billingNavDiv" style={{ paddingLeft: 15 }} >
                            <input
                                type="checkbox"
                                id="acceptOffice365Req"
                                style={{ marginRight: 3 }}
                                checked={this.state.hasOffice365 ? 'checked' : null}
                                onChange={e => { this.setState({ hasOffice365: e.target.checked }) }} />
                            <label htmlFor="acceptOffice365Req" style={{ display: 'inline' }}>
                                <strong>I understand that I need a valid Microsoft Office 365 subscription to use FormulaSuite add-in</strong>
                            </label>
                        </div>
                        <div
                            id="navgationBtnsDiv"
                            className={this.updateBilling ? ' d-none' : 'null'}
                            style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                            <button className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={this.toPurchaseSummary}>Back</button>
                            <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={this.completePayment}>{`Pay $${accountManagementData.amountDue.toFixed(2)}`}</button>
                        </div>
                    </>) :
                    (
                        <div id="updateBillingControls"
                            className={this.updateBilling ? 'null' : 'd-none'}
                            style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '3px' }}>
                            <button className="btn btn-sm btn-secondary mr-3" style={{ float: 'left' }} onClick={this.toAccountManagement}>Cancel</button>
                            <button className="btn btn-sm btn-danger" style={{ float: 'left' }} onClick={this.clearBillingInfo}>Clear Billing Info</button>
                            <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={this.updateBillingDetails}>Update</button>
                        </div>

                    )
                }
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment >

        );
    }
}
BillingDetails.contextType = AuthContext;
export default withRouter(BillingDetails);