import React from 'react';
import DataTable from 'react-data-table-component';
import { getSubscription, inviteUser, revokeUser } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { constructUserTableData, getUserTableColumns } from '../../util/InviteUserUtil';
import { waitForLoad } from '../../util/Loader';
import { isValidEmail } from '../../util/Util';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

class UserManagement extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.confirmElement = React.createRef();
        this.state = {
            invitedUsers: [],
            isLoading: true,
            data: [],
            inviteEmail: null,
            availableUsers: 0
        }
    }
    componentDidMount = () => {
        getSubscription(this.context.user, this.context.tokenId, this.context.token).then(subscriptionResponse => {
            if (isSuccess(subscriptionResponse.status)) {
                this.setState({ isLoading: false }, () => {
                    this.prepareUserTable(subscriptionResponse);
                });

            } else {
                this.setState({ isLoading: false }, () => {
                    this.getDialog().status(subscriptionResponse.data.message);
                });
            }

        }).catch(e => {
            this.getDialog().status(e.message);
        })

    }
    prepareUserTable = (subscriptionDetailResponse) => {
        if (!subscriptionDetailResponse)
            return;
        const { uiAccountSubscriptionInfo, invitedUsers } = subscriptionDetailResponse.data;
        const data = constructUserTableData(invitedUsers);
        this.setState({
            isLoading: false,
            data: data,
            availableUsers: uiAccountSubscriptionInfo.totalAdditionalUsers,
            invitedUsers: invitedUsers ? invitedUsers : [],
            inviteEmail: null
        });
    }
    deleteUser = (email) => {
        this.getDialog().prompt('Are you sure you want to delete?', () => {
            this.setState({ isLoading: true });
            revokeUser(this.context, email).then(response => {
                if (isSuccess(response.status)) {
                    getSubscription(this.context.user,
                        this.context.tokenId,
                        this.context.token).then((subscriptionRes) => {
                            this.context.subscriptionDetailResponse = subscriptionRes;
                            this.prepareUserTable(subscriptionRes);
                        });

                } else {
                    this.setState({ isLoading: false }, () => {
                        this.getDialog().status(response.data.message);
                    })
                }


            }).catch((e) => {
                this.setState({ isLoading: false }, () => {
                    this.getDialog().status(e.message);
                });
            });
        })
    }
    getDialog = () => {
        return this.confirmElement.current;
    }
    validateInviteForm = () => {
        let isValid = true;
        if (!this.state.inviteEmail)
            isValid = false;
        if (!isValidEmail(this.state.inviteEmail)) {
            this.getDialog().status('You have entered an invalid email address. Please re-enter');
            isValid = false;
        }
        if (this.state.invitedUsers.length >= this.state.availableUsers) {
            this.getDialog().status('The number of users you have added has reached the maximum allowed for your subscription plan and add-on purchase/s, if any. To add more users, please upgrade your plan or purchase access for additional users in the Account Management page.');
            isValid = false;
        }
        return isValid;
    }
    confirmInvite = () => {
        if (this.validateInviteForm()) {
            this.getDialog().prompt('You are inviting the user. Please make sure he has Microsoft Office 365 subscription. Are you sure you want to invite?', () => {
                this.setState({ isLoading: true });
                inviteUser(this.context, this.state.inviteEmail).then(async (response) => {
                    if (isSuccess(response.status)) {
                        getSubscription(this.context.user, this.context.tokenId, this.context.token).then((subResponse) => {
                            if (isSuccess(subResponse.status)) {
                                this.prepareUserTable(subResponse);
                            } else {
                                this.setState({ isLoading: false }, () => {
                                    this.getDialog().status(subResponse.data.message);
                                });
                            }
                        }).catch(e => {
                            this.setState({ isLoading: false }, () => {
                                this.getDialog().status(e.message);
                            });
                        })
                    } else {
                        this.setState({ isLoading: false }, () => {
                            this.getDialog().status(response.data.message);
                        });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false }, () => {
                        this.getDialog().status(e.message);
                    });
                })
            });
        }


    }

    userInvitedMessage = () => {
        const confirm = this.confirmElement.current;
        confirm.status('The user you invited will now have access to FormulaSuite. You can revoke access by deleting user.')
    }

    confirmDelete = () => {
        const confirm = this.confirmElement.current;
        confirm.prompt('Are you sure you want to delete?');
    }

    render() {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }
    renderFn = () => {
        const columns = getUserTableColumns(this);
        return (
            <React.Fragment>
                <PanelTitle panelName="Manage users" />
                <div style={{ paddingLeft: 10, paddingTop: 9, paddingRight: 10 }}>
                    <div className="alert alert-primary" role="alert" style={{ marginTop: 10, width: '50%' }}>
                        You have used <strong>{this.state.invitedUsers.length}</strong> of available <strong>{this.state.availableUsers}</strong> users.
                    </div>
                    <div style={{ width: 384, /* border: '1px solid lightgray', */marginTop: 9, borderRadius: 6 }}>
                        <form className="form-inline mt-4" onSubmit={e => { e.preventDefault(); this.confirmInvite(); }}>
                            <div className="form-group">
                                <label style={{ fontWeight: 'bold' }} className='required-label'>User email</label>
                                <input
                                    type="text"
                                    id="inviteEmail"
                                    className="form-control form-control-sm ml-2 "
                                    style={{ height: 25, width: 231 }}
                                    value={this.state.inviteEmail}
                                    onChange={e => this.setState({ inviteEmail: e.target.value })}
                                />
                                <button className="btn btn-primary btn-sm ml-2 " style={{ height: 25, paddingTop: '2px' }} onClick={this.confirmInvite}>Invite</button>
                            </div>
                        </form>
                    </div>
                    <div style={{ width: '50%', height: '52vh' }} className='mt-3 overflow-auto'>
                        <DataTable title={null} striped={true} dense columns={columns} data={this.state.data} />
                    </div>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment>);
    }
}

UserManagement.contextType = AuthContext;
export default UserManagement;