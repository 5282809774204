import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class HomeAdmin extends React.Component {

    render() {
        return (
            <div>
                <Alert variant="primary">Your account is associated as a guest or additional paid user for another account. <Link to="/accountAccess" className="blueLink" style={{ textDecoration: 'underline' }}>Click here to view account associations</Link></Alert>
                <div className=" d-flex fsTails">
                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/manageSubscription">Manage Subscription</Link></div>
                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/manageUsers">Manage Users</Link></div>
                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/updateBillingDetails">Update Billings/<br />View Transactions</Link></div>
                </div>
                <div className="d-flex fsTails">
                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/updateProfile">Update Profile</Link></div>
                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/changeAccountOwner">Change Account Owner</Link></div>
                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/help">Help</Link></div>
                </div>
            </div>

        );
    }
}

export default HomeAdmin;

