class SubscriptionDetails {
    constructor(emailId, planId, companyName, purchase, noOfSeats, uiBillingsInfo, coupon, creteDefaultPlan, revokeUsers) {
        this.emailId = emailId;
        this.planId = planId;
        this.companyName = companyName;
        this.purchase = purchase;
        this.noOfSeats = noOfSeats;
        this.uiBillingsInfo = uiBillingsInfo;
        this.coupon = coupon;
        this.creteDefaultPlan = creteDefaultPlan;
        this.revokeUsers = revokeUsers;
    }
}

export default SubscriptionDetails;