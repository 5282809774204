import React from 'react';
import { getProfile, updateProfile } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { countryOptions, usStatesOptions } from '../../util/Countries';
import { waitForLoad } from '../../util/Loader';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

class UpdateProfile extends React.Component {
    constructor() {
        super();
        this.state = {
            showStateDdl: true,
            country: 'US',
            firstName: null,
            lastName: null,
            company: null,
            industry: '',
            otherIndustry: null,
            address1: null,
            address2: null,
            city: null,
            state: 'AL',
            zip: null,

        };
        this.industryDB = null;
        this.confirmElement = React.createRef();
    }

    componentDidMount = () => {
        this.fetchUserProfile();
    }
    fetchUserProfile = () => {
        getProfile(this.context)
            .then((response) => {
                if (isSuccess(response.status)) {
                    const uiUser = response.data.uiUser;
                    const profile = uiUser.profile;
                    this.setProfile(profile);
                }
                else
                    this.getDialog().status(response.data.message);
            })
            .catch(e => {
                this.getDialog().status(e.message);
            });

    }
    setProfile = (profile) => {
        debugger;
        if (!profile)
            profile = {};
        let industry = profile.industry ? profile.industry : this.state.industry;
        let otherIndustry = null;
        if (profile.industry) {
            industry = (profile.industry.includes('I_')) ? profile.industry : 'OTHER';
            otherIndustry = industry === 'OTHER' ? profile.industry : null;
        }

        this.setState({
            firstName: profile.firstName,
            lastName: profile.lastName,
            company: profile.company,
            industry: industry,
            otherIndustry: otherIndustry,
            address1: profile.address1,
            address2: profile.address2,
            city: profile.city,
            state: profile.state,
            country: profile.country,
            zip: profile.zip

        });
    }
    getDialog = () => {
        return (this.confirmElement.current);
    }
    industryChanged = (industry) => {
        this.setState({ industry: industry });
    }
    goToDashboard = () => {
        this.props.history.push('/home');
    }

    confirmProfileSave = () => {
        const confirm = this.confirmElement.current;
        confirm.prompt('Are you sure you want to save?', this.saveProfile);
    }
    saveProfile = async () => {
        const updateProfileReq = {};
        updateProfileReq.emailId = this.context.user;
        const profile = {};
        profile.firstName = this.state.firstName;
        profile.lastName = this.state.lastName;
        profile.company = this.state.company;
        profile.industry = this.state.industry === 'OTHER' ? this.state.otherIndustry : this.state.industry;
        profile.address1 = this.state.address1;
        profile.address2 = this.state.address2;
        profile.city = this.state.city;
        profile.state = this.state.state;
        profile.country = this.state.country;
        profile.zip = this.state.zip;
        updateProfileReq.profile = profile;
        updateProfile(updateProfileReq, this.context).then((response) => {
            if (isSuccess(response.status)) {
                const uiUser = response.data.uiUser;
                const profile = uiUser.profile;
                this.setProfile(profile);
            }
            else
                this.getDialog().status(response.data.message);
        }).catch(e => {
            this.getDialog().status(e.message);
        });
    }
    countryChanged = (country) => {
        const showStateDdl = country == 'US';
        this.setState({ country: country, showStateDdl: showStateDdl });
    }


    getStateControl = () => {
        if (this.state.showStateDdl)
            return (
                <div className="form-group row" >
                    <label className="col-md-3 col-form-label-sm" htmlFor="address">State</label>
                    <div className="col-md-9">
                        <select
                            type="text"
                            className="form-control form-control-sm"
                            id="stateDdl"
                            value={this.state.state}
                            onChange={e => { this.setState({ state: e.target.value }); }} >
                            {usStatesOptions}
                        </select>
                    </div>
                </div>

            );
        else
            return (
                <div className="form-group row" >
                    <label className="col-md-3 col-form-label-sm" htmlFor="address">State</label>
                    <div className="col-md-9">
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id="state"
                            value={this.state.state}
                            onChange={e => { this.setState({ state: e.target.value }); }} />
                    </div>
                </div>
            );


    }
    render() {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }
    renderFn = () => {
        debugger;

        return (
            <React.Fragment>
                <PanelTitle panelName="Update profile" />
                <div style={{ height: '400px', overflow: 'auto', borderBottom: '1px solid lightgray' }}>
                    <div id="updateUsersDiv" style={{ paddingLeft: '10px', paddingTop: '9px', width: '50%' }}>
                        <form className="mt-2 container-fluid">
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="name">First name</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm "
                                        id="name" value={this.state.firstName}
                                        onChange={(e) => { this.setState({ firstName: e.target.value }) }} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="name">Last name</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm "
                                        id="lastName"
                                        value={this.state.lastName}
                                        onChange={(e) => { this.setState({ lastName: e.target.value }) }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="email">Email</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm "
                                        id="email"
                                        value={this.context.user}
                                        readOnly />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="email">Company</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="company"
                                        value={this.state.company}
                                        onChange={e => { this.setState({ company: e.target.value }) }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="industry">Industry</label>
                                <div className="col-md-9">
                                    <select
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="industry"
                                        value={this.state.industry}
                                        onChange={e => { this.industryChanged(e.target.value) }}>
                                        <option value="">Select an industry</option>
                                        <option value="I_ACCOUNTING">Accounting</option>
                                        <option value="I_AUTOMOTIVE">Automotive</option>
                                        <option value="I_COMPUTER_SOFTWARE_ENGINEERING">Computer Software/Engineering</option>
                                        <option value="I_CONSUMER_GOODS">Consumer Goods</option>
                                        <option value="I_EDUCATION">Education</option>
                                        <option value="I_FOOD_BEVERAGES">Food/Beverages</option>
                                        <option value="I_FINANCIAL_SERVICES">Financial Services</option>
                                        <option value="I_GOVERNMENT_ADMINISTRATION">Government Administration</option>
                                        <option value="I_HEALTHCARE">Healthcare</option>
                                        <option value="I_HOSPITALITY">Hospitality</option>
                                        <option value="I_INSURANCE">Insurance</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                    <div style={{ marginTop: 6, fontStyle: 'italic' }} className={this.state.industry == 'OTHER' ? '' : 'd-none'}>
                                        <div>
                                            Please specify the industry.
                                    </div>
                                        <input
                                            type="text"
                                            className={`form-control form-control-sm`}
                                            id="otherIndustry"
                                            value={this.state.otherIndustry}
                                            onChange={e => { this.setState({ otherIndustry: e.target.value }); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="address">Address1</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="address1"
                                        value={this.state.address1}
                                        onChange={e => this.setState({ address1: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="address">Address2</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="address2"
                                        value={this.state.address2}
                                        onChange={e => this.setState({ address2: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="address">City</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="city"
                                        value={this.state.city}
                                        onChange={e => this.setState({ city: e.target.value })}
                                    />
                                </div>
                            </div>
                            {this.getStateControl()}
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="address">Country</label>
                                <div className="col-md-9">
                                    <select
                                        className="form-control form-control-sm"
                                        id="country"
                                        onChange={(e) => { this.countryChanged(e.target.value) }}
                                        value={this.state.country}
                                    >
                                        {countryOptions}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label-sm" htmlFor="address">Zip</label>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="zip"
                                        value={this.state.zip}
                                        onChange={e => { this.setState({ zip: e.target.value }) }}
                                    />
                                </div>
                            </div>

                        </form>

                    </div >

                </div>
                <div style={{ paddingLeft: '15px', paddingRight: '22px', width: '50%', paddingTop: '9px' }}> <button className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={this.goToDashboard}>Cancel</button><button style={{ float: 'right' }} className="btn btn-sm btn-primary" onClick={this.confirmProfileSave}>Update</button> </div >
                <Confirm ref={this.confirmElement} show={false} />
            </React.Fragment >

        );
    }
}
UpdateProfile.contextType = AuthContext;
export default UpdateProfile;