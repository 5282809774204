import { render } from '@testing-library/react';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getSubscription, update, reactivateSubscription } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import { waitForLoad } from '../../util/Loader';
import PropTypes from "prop-types";
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import AccountManagementData from '../../model/AccountManagentData';
import Display from '../../constants/Display';
import SubscriptionDetails from '../../model/SubscriptionDetails';
import { isSuccess } from '../../constants/Status';
import { getSubscriptionEndDate, isInactive, isTrial } from '../../util/Util';



class ManageSubscription extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    static messages = {
        SUBSCRIPTION_UPDATED: 'Your subscription plan has been updated',
        SUBSCRIPTION_WILL_UPDATE_AT_EOP: 'Your subscription plan will be updated at the end of current subscription period'
    }

    constructor(props) {
        super(props);
        this.state = {
            showPlanDdl: false,
            selectedPlan: null,
            selectedPlanInDdl: null,
            showAddonInput: false,
            subscriptionResponse: null,
            uiAccountSubscriptionInfo: null,
            showPrompt: false,
            paidUserCount: 0,
            isLoading: true,
            nextChargeAt: null
        };
        this.confirmElement = React.createRef();
        this.plans = [
            {
                id: 'formulasuite_annual_subscription',
                displayName: 'Formulasuite annual subscription'
            },
            {
                id: 'formulasuite_monthly_subscription',
                displayName: 'Formulasuite monthly subscription'
            }
        ];

    }

    componentDidMount = () => {
        if (this.props.accountManagementData())
            this.props.setAccountManagementData(null);
        this.getSubscriptionDetails();
    }



    /***************************Event handlers ****************************/
    changeQuantityClicked = () => {
        const confirm = this.confirmElement.current;
        const { uiAccountSubscriptionInfo } = this.state;
        const paidUserCountDb = uiAccountSubscriptionInfo.noOfSeats;
        if (paidUserCountDb === this.state.paidUserCount) {
            confirm.status('Please modify number of users and then click change');
        }
        else if (isInactive(uiAccountSubscriptionInfo.subscriptionStatus)) {
            this.getDialog().status('Modifications to your subscription is not allowed as your subscription is in Inactive state. First you need to reactivate your account by clicking on Reactivate link');
        }
        else if (paidUserCountDb < this.state.paidUserCount) {
            if (isTrial(uiAccountSubscriptionInfo.statusDisplayName)) {
                this.upgradeTrialPlan();
            } else {
                const uiAccountSubscriptionInfo = this.state.uiAccountSubscriptionInfo;
                const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo, this.state.selectedPlan, this.state.paidUserCount);
                this.props.setAccountManagementData({ ...accountManagementData });
                this.props.history.push(`${this.props.accPath}/purchaseSummary`);
            }

        }
        else if (paidUserCountDb > this.state.paidUserCount) {
            const diff = paidUserCountDb - this.state.paidUserCount;
            this.getDialog().prompt(`You are reducing number of seats by ${diff}. Any users you would have assigned to these seat/s will loose access to FormulaSuite. Are you sure you want to continue?`, this.reduceSeats);

        }
    }

    planSelectorChanged = (e) => {
        this.setState({ selectedPlanInDdl: e.target.value }, () => {
            this.changePlan();
        });

    }

    /***************************End of event handlers****************************************/

    getSubscriptionDetails = async () => {
        const { user, tokenId, token } = this.context;
        getSubscription(user, tokenId, token).then(response => {
            if (isSuccess(response.status)) {
                const { uiAccountSubscriptionInfo } = response.data;
                let nextChargeAtString = getSubscriptionEndDate(uiAccountSubscriptionInfo);
                this.setState({
                    subscriptionResponse: response,
                    isLoading: false,
                    paidUserCount: uiAccountSubscriptionInfo.noOfSeats,
                    selectedPlan: uiAccountSubscriptionInfo.planCode,
                    selectedPlanInDdl: uiAccountSubscriptionInfo.planCode,
                    uiAccountSubscriptionInfo: uiAccountSubscriptionInfo,
                    nextChargeAt: nextChargeAtString
                });
                const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo);
                this.props.setAccountManagementData({ ...accountManagementData });
                const auth = this.context;
                auth.subscriptionDetailResponse = response;
                this.props.setAppState({ ...auth });
            } else {
                this.setState({ isLoading: false }, () => {
                    this.getDialog().status(response.data.message);
                });

            }

        }).catch(e => {
            this.setState({ isLoading: false }, () => {
                this.getDialog().status(e.message);
            });

        });


    }

    reduceSeats = () => {
        let invitedUsers = this.state.subscriptionResponse.data.invitedUsers;
        invitedUsers = invitedUsers ? invitedUsers : [];
        const { uiAccountSubscriptionInfo } = this.state;
        const paidUserCountDb = uiAccountSubscriptionInfo.noOfSeats;
        if (invitedUsers && (invitedUsers.length > this.state.paidUserCount)) {
            const accountManagementData = new AccountManagementData();
            accountManagementData.invitedUsers = invitedUsers;
            accountManagementData.seatsToReduce = (invitedUsers.length - uiAccountSubscriptionInfo.noOfGuestSeats) - this.state.paidUserCount;
            accountManagementData.seatReducedBy = paidUserCountDb - this.state.paidUserCount;
            accountManagementData.selectedPlan = this.getSelectedPlan();
            accountManagementData.uiAccountSubscriptionInfo = { ...uiAccountSubscriptionInfo };
            this.props.setAccountManagementData({ ...accountManagementData });
            this.props.history.push(`${this.props.accPath}/reduceUsers`);
        } else
            this.downgradePlan();
    }


    changePlan = () => {
        const { uiAccountSubscriptionInfo } = this.state.subscriptionResponse.data;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        let isDowngrade = (this.state.selectedPlan === 'formulasuite_annual_subscription' && this.state.selectedPlanInDdl === 'formulasuite_monthly_subscription');
        if (isInactive(uiAccountSubscriptionInfo.subscriptionStatus)) {
            this.getDialog().status('Modifications to your subscription is not allowed as your subscription is in Inactive state. First you need to reactivate your account by clicking on Reactivate link', this.resetPlan);
        } else if (isDowngrade) {
            const msg = (isTrial ? 'You are downgrading your plan. Are you sure you want to proceed?' :
                'You are downgrading your plan. Changes will be effective after current billing period. Are you sure you want to proceed?');
            this.getDialog().prompt(msg, this.downgradePlan, this.resetPlan);
        } else {

            if (isTrial)
                this.getDialog().prompt('Are you sure you want to update your plan?', this.upgradeTrialPlan, this.resetPlan);
            else
                this.getDialog().prompt('Your change/s will be effective upon confirmation and payment update. Do you want to continue?', this.upgradePlan, this.resetPlan);

        }


    }

    downgradePlan = () => {

        const auth = this.context;
        const { uiAccountSubscriptionInfo } = this.state.subscriptionResponse.data;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        const selectedPlan = this.getSelectedPlan();

        let successMsg = this.getMessages().SUBSCRIPTION_UPDATED;
        !isTrial && this.isPlanChangeOperation() && (successMsg = this.getMessages().SUBSCRIPTION_WILL_UPDATE_AT_EOP);
        const subscriptionDetails = new SubscriptionDetails(
            auth.user,
            selectedPlan,
            null,
            !isTrial,
            this.state.paidUserCount,
            null,
            null,
            null
        );
        this.setState({ isLoading: true });

        update(auth, subscriptionDetails)
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    if (isSuccess(response.status)) {
                        this.getDialog().status(successMsg, this.refreshPlan);
                    } else {
                        this.getDialog().status(response.data.message);
                        this.setState({ showPlanDdl: false, selectedPlanInDdl: this.state.selectedPlan });
                    }

                });

            }).
            catch(error => {
                this.setState({ isLoading: false }, () => {
                    this.getDialog().status(error);
                });

            });

    }

    upgradeTrialPlan = () => {
        const auth = this.context;
        const { uiAccountSubscriptionInfo } = this.state.subscriptionResponse.data;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        const selectedPlan = this.getSelectedPlan();
        const subscriptionDetails = new SubscriptionDetails(
            auth.user,
            selectedPlan,
            null,
            !isTrial,
            this.state.paidUserCount,
            null,
            null,
            null
        );
        this.setState({ isLoading: true });

        update(auth, subscriptionDetails)
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    if (isSuccess(response.status)) {
                        this.getDialog().status('Your subscription plan has been updated', this.refreshPlan);

                    } else {
                        this.getDialog().status(response.data.message);
                        this.setState({ showPlanDdl: false, selectedPlanInDdl: this.state.selectedPlan });
                    }

                });

            }).
            catch(error => {
                this.setState({ isLoading: false }, () => {
                    this.getDialog().status(error);
                });

            });

    }
    upgradePlan = () => {
        const { uiAccountSubscriptionInfo } = this.state.subscriptionResponse.data;
        const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo, this.state.selectedPlanInDdl);
        this.props.setAccountManagementData({ ...accountManagementData });
        this.props.history.push(`${this.props.accPath}/purchaseSummary`);
    }

    /***************************Util functions************************/
    getMessages = () => {
        return ManageSubscription.messages;
    }
    getDialog = () => {
        return (this.confirmElement.current);
    }

    resetPlan = () => {
        this.setState({ selectedPlanInDdl: this.state.selectedPlan });
    }

    refreshPlan = () => {
        this.setState({ showPlanDdl: false, isLoading: true }, () => {
            this.getSubscriptionDetails();
        });

    }
    isPlanChangeOperation = () => {
        return this.state.showPlanDdl && this.state.selectedPlanInDdl !== this.state.selectedPlan;
    }

    getSelectedPlan = () => {
        return this.isPlanChangeOperation() ? this.state.selectedPlanInDdl : this.state.selectedPlan
    }

    getStatusButton = () => {
        const { subscriptionStatus, statusDisplayName } = this.state.uiAccountSubscriptionInfo;
        if (statusDisplayName === Display.STATUS_TRIAL) {
            return (
                <Link id="convertTrial"
                    className="btn btn-sm btn-success"
                    style={{ height: '25px', fontSize: '14px', lineHeight: '0.5', paddingTop: 7 }}
                    name="change-quantity-am"
                    to={{
                        pathname: `${this.props.accPath}/purchaseSummary`,
                        data: {
                            selectedPlan: this.state.selectedPlan
                        }
                    }}
                >Buy now
                </Link>
            )
        } else if (isInactive(subscriptionStatus)) {
            return (
                <button id="reactivateBtn"
                    className="btn btn-sm btn-success"
                    style={{ height: '25px', fontSize: '14px', lineHeight: '0.5' }}
                    name="change-quantity-am" onClick={() => { this.confirmReactivate() }}>Reactivate
                </button>
            )
        }
        else
            return null;

    }

    /***************************End of Util functions************************/
    confirmCancel = () => {
        const confirm = this.confirmElement.current;
        confirm.prompt('FormulaSuite functions that you (and guest and paid users in your account) use with your spreadsheet will not update values after cancellation. Do you want to continue?', this.goToConfirmCancel);
    }

    confirmReactivate = () => {
        const isNonRenewing = Display.NON_RENEWING == this.state.uiAccountSubscriptionInfo.subscriptionStatus;
        if (isNonRenewing) {
            this.getDialog().prompt('Are you sure you want to reactivate?', () => {
                this.setState({ isLoading: true });
                reactivateSubscription(this.context).then((response) => {
                    this.refreshPlan();
                }).catch((e) => {
                    this.getDialog().status(e.getMessage());
                });
            });
        } else {
            this.props.history.push(`${this.props.accPath}/confirmReactivation`);
        }

    }

    goToConfirmCancel = () => {
        this.props.history.push(`${this.props.accPath}/confirmCancellation`);
    }



    render() {
        return waitForLoad(this.state.isLoading, this.renderFn);

    }
    renderFn = () => {
        const displayDdl = this.state.showPlanDdl ? '' : 'none';
        const displayPlan = this.state.showPlanDdl ? 'none' : '';
        const { planName, noOfSeats, statusDisplayName } = this.state.uiAccountSubscriptionInfo;
        const { invitedUsers, uiAccountSubscriptionInfo } = this.state.subscriptionResponse.data;
        return (
            <React.Fragment>
                <PanelTitle panelName="Manage Subscription" />

                <div id="planDetailsDiv" style={{ paddingLeft: '10px', paddingTop: '9px' }}>
                    <div className="container-fluid">
                        <div className="row" style={{ backgroundColor: '#ccc', fontWeight: 'bold' }}>
                            <div className="col-sm-12">Your current subscription</div></div>

                        <div id="billingFrequencySpan"
                            className="row plan-detail-row mt-1"
                            style={{ display: displayPlan }}>
                            <div className="col-md-4">Plan</div>
                            <div className="col-md-6">
                                <label id="billingFrequency">{planName}</label>
                            </div>
                            <div className="col-md-2">
                                {Display.CANCELLED === uiAccountSubscriptionInfo.subscriptionStatus ? null : (<a onClick={() => this.setState({ showPlanDdl: true })}
                                    id="showBillingFrequencyLink"
                                    className="blueLink"
                                    style={{ fontWeight: 'normal', fontSize: '13px' }}>Change
                                </a>)}

                            </div>
                        </div>
                        <div className="row plan-detail-row mt-1"
                            id="frequencyDdlSpan"
                            style={{ display: displayDdl }}>
                            <div className="col-md-4">Plan</div>
                            <div className="col-md-6">
                                <select id="planSelector"
                                    className="form-control form-control-sm"
                                    value={this.state.selectedPlanInDdl}
                                    onChange={(e) => { this.planSelectorChanged(e) }}>
                                    <option value="formulasuite_monthly_subscription">Formulasuite monthly subscription</option>
                                    <option value="formulasuite_annual_subscription">Formulasuite annual subscription</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <a className="blueLink"
                                    onClick={() => this.setState({ showPlanDdl: false })}
                                    style={{ fontSize: '13px' }}>Close
                            </a>
                            </div>
                        </div>
                        <div className="row plan-detail-row" id="status-row">
                            <div className="col-md-4">Status</div>
                            <div className="col-md-6"
                                style={{ fontWeight: 'bold' }}>
                                <label className="d-inline-block mb-0" style={{ width: 45, marginRight: '7px', marginBottom: 0 }}>
                                    {statusDisplayName}
                                </label>
                                {this.getStatusButton()}

                            </div>
                            {(Display.CANCELLED === uiAccountSubscriptionInfo.subscriptionStatus
                                || Display.NON_RENEWING === uiAccountSubscriptionInfo.subscriptionStatus) ? (
                                null) : (<div className="col-md-2">
                                    <a className="blueLink"
                                        onClick={this.confirmCancel}
                                        style={{ fontWeight: 'normal', fontSize: '13px' }}>Cancel
                                     </a>
                                </div>)}

                        </div>
                        <div className="row plan-detail-row">
                            <div className="col-md-4">Number of paid users</div>
                            <div className="col-md-8" id="addon-quantity-parent" style={{ display: '' }}>
                                <span className="addon-quantity-am d-inline-block" addon-subscribed="false">
                                    <input id="noOfUsers"
                                        type="number"
                                        min="0"
                                        className="integerField quantity-field"
                                        value={this.state.paidUserCount}
                                        onChange={(e) => { this.setState({ paidUserCount: Math.abs(e.target.value) }) }}
                                    />
                                </span>
                                <button id="changeQuantity"
                                    className="btn btn-sm btn-success"
                                    style={{ height: '25px', fontSize: '14px', lineHeight: '0.5', width: 67 }}
                                    name="change-quantity-am" onClick={() => { this.changeQuantityClicked() }}>Change
                            </button>
                            </div>

                        </div>
                        <div className="row plan-detail-row" id="quantity-availability">
                            <div className="col-md-4"></div>
                            <div className="col-md-8">
                                <strong>You have used {invitedUsers ? invitedUsers.length : 0} of available {this.state.uiAccountSubscriptionInfo.totalAdditionalUsers} users</strong>
                            </div>
                        </div>
                        <div className="row plan-detail-row">
                            <div className="col-md-4">Next charge</div>
                            <div className="col-md-8"><strong>{this.state.nextChargeAt}</strong></div>
                        </div>

                    </div>
                </div>
                <Confirm ref={this.confirmElement} show={false} />
            </React.Fragment>);
    }
}

ManageSubscription.contextType = AuthContext;
export default withRouter(ManageSubscription);