import React from 'react';
import fsLogo from '../../images/fs-logo-dashboard.svg';
import { IconContext } from "react-icons";
import { IoPersonCircleOutline } from "react-icons/io5";
import { AiFillHome } from "react-icons/ai";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';




class NavBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showUserPop: false,
        }
    }

    logout = () => {
        this.props.logoutFn();
    }

    popover = (
        <Popover id="user-pop">
            <Popover.Title as="h3">   </Popover.Title>
            <Popover.Content>
                <div className="popover-body">
                    <div>
                        <IoPersonCircleOutline display="block" cursor="pointer" width="50" color="#660066" size="40" style={{ margin: 'auto' }} />
                    </div>
                    <div className="d-inline-block" style={{ width: '100%', marginBottom: '10px' }}>
                        <p className="user-pop-info" style={{ textTransform: 'lowercase', marginBottom: '0px', color: 'purple', fontWeight: 'bold' }}>{this.props.user}</p>

                    </div>
                    <p style={{ fontWeight: 'bold', marginBottom: '0px' }}><Link to="/changePassword" className="blueLink" style={{ paddingRight: '14px', borderRight: '1px solid black' }}>Change password</Link><a className="blueLink" style={{ paddingLeft: '14px' }} onClick={this.logout}>Logout</a></p>
                </div>
            </Popover.Content>
        </Popover>
    );


    render() {
        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-sm navbar-dark bg-primary" id="fs-app-bar" >

                    <Link to="/home" className="navbar-brand" style={{ fontWeight: 'bold', fontWeight: 'normal', fontSize: 19 }} >
                        <img src={fsLogo} width="210px" height="150px" style={{ marginTop: -64, marginBottom: -63 }} />
                    </Link>
                    <label style={{ marginBottom: 0, fontWeight: 'normal', fontSize: 19 }}>by Seqnc</label>
                    {
                        this.props.passwordExpired ? '' :
                            <Link to="/home" className="navbar-brand ml-4" style={{ fontWeight: 'bold', fontWeight: 'normal', fontSize: 19 }} ><AiFillHome color="#660066" width="50" size="22" /></Link>

                    }

                    <div className="ml-auto">
                        <span id="user-span"> <OverlayTrigger trigger="click" placement="bottom" overlay={this.popover} rootClose={true}><IoPersonCircleOutline display="block" cursor="pointer" width="50" color="#660066" size="30" /></OverlayTrigger></span>
                    </div>

                </nav >
            </React.Fragment>

        );
    }
}

export default NavBar;