import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './Home';
import ChangeAccountOwner from '../users/ChangeAccountOwner';
import UpdateProfile from '../other/UpdateProfile';
import HomeAdmin from '../other/HomeAdmin';
import AccountAccess from '../other/AccountAccess';
import HomeInvited from '../other/HomeInvited';
import ChangePassword from '../auth/ChangePassword';
import ManageAccount from '../subscriptions/ManageAccount';
import UserManagement from '../users/UserManagement';


const ContentArea = (props) => {

    return (
        <div id="content-area" style={{ height: '85vh' }}>

            <div className="sq-panel">
                <Switch>

                    <Route path="/" exact  >
                        <Home setAppState={props.setAppState} />
                    </Route>
                    <Route path="/home" exact  >
                        <Home setAppState={props.setAppState} />
                    </Route>
                    <Route path="/dashboard" exact >
                        <Home setAppState={props.setAppState} />
                    </Route>
                    <Route path="/homeAdmin" exact component={HomeAdmin} />
                    <Route path="/homeInvited" exact component={HomeInvited} />
                    <Route path="/updateProfile" exact component={UpdateProfile} />
                    <Route path="/manageAccount">
                        <ManageAccount setAppState={props.setAppState} />
                    </Route>
                    <Route path="/manageUsers" exact>
                        <UserManagement setAppState={props.setAppState} />
                    </Route>
                    <Route path="/changeAccountOwner" exact >
                        <ChangeAccountOwner setAppState={props.setAppState} />
                    </Route>
                    <Route path="/accountAccess" exact component={AccountAccess} />
                    <Route path="/changePassword" exact component={ChangePassword} />
                    <Redirect to="/home" />

                </Switch>


            </div>

        </div >

    );
};

export default ContentArea;