import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import fsLogo from '../../images/fs-logo.svg';
import { AddinLoginRequest, AddinSignUpRequest } from '../../requests/AccountRequests';
import Auth from '../../model/Auth';
import { login, requestPasswordReset, signup } from '../../api/account';
import Confirm from '../common/Confirm';
import AuthContext from '../../contexts/AuthContext';
import { isSuccess } from '../../constants/Status';
import { waitForLoad } from '../../util/Loader';
import { isValidEmail } from '../../util/Util';
import PropTypes from "prop-types";
class ForgotPassword extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            email: '',
            isLoading: false
        };
        this.confirmElement = React.createRef();
    }

    getDialog = () => {
        return this.confirmElement.current;
    }

    goToLogin = () => {
        this.props.history.push('/');
    }
    validate = () => {
        const email = this.state.email;
        let isValid = true;
        if (!email) {
            this.getDialog().status('Enter email addrees');
            isValid = false;
        }
        if (!isValidEmail(email)) {
            this.getDialog().status('Enter a valid email address');
            isValid = false;
        }

        return isValid;
    }
    invokeResetPassword = () => {
        const { email } = this.state;
        if (!this.validate())
            return;
        this.setState({ isLoading: true });
        requestPasswordReset(email).then(response => {
            this.setState({ isLoading: false }, () => {
                if (isSuccess(response.status))
                    this.getDialog().status('A link will be emailed with password set/reset instructions if the account information you provided is valid. (Note: Please check your spam folder if you don’t receive the reset email.)', this.goToLogin);
                else
                    this.getDialog().status(response.data.message);
            })


        })
            .catch(function (error) {
                console.log('fs error', error);
            });
        ;
    }

    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }

    renderFn = () => {
        return (
            <React.Fragment>
                <div id="loginDiv" className="pl-2 pr-2">
                    <div className="mb-4"><img src={fsLogo} style={{ height: '108px', width: '402px', marginTop: '-21px', marginBottom: '-29px' }} /></div>
                    <form className="container-fluid" onSubmit={(e) => { e.preventDefault(); this.invokeResetPassword(); }}>
                        <div className="form-group">
                            <label className="required-label">Enter your email</label>
                            <input type="email" id="email" className="form-control form-control-sm" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }); }} />
                        </div>
                        <button type="button" className="btn btn-primary btn-sm w-100" onClick={this.invokeResetPassword}><strong>Submit</strong></button>
                        <div className="field-note mt-1">An email with instructions on how to set/reset your password will be sent if the username you provided is valid.
                        <p>
                                Note: Please check your spam folder if you don’t receive the reset email. Also, please add our email to your contact list to ensure email delivery.</p></div>
                        <div className="mt-5" style={{ textAlign: 'center' }}>
                            <Link to="/" className="blue-link"><strong>Back to login</strong></Link>
                        </div>
                    </form>
                </div >
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment >
        );

    }
}
ForgotPassword.contextType = AuthContext;
export default withRouter(ForgotPassword);