import React from 'react';
import { getInviters } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { getDataTable } from '../../util/TableUtil';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

class AccountAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            inviters: []
        }
        this.confirmElement = React.createRef();
        this.columns = this.getInvitersTableColumns();
    }
    componentDidMount = () => {
        debugger;
        getInviters(this.context).then(response => {
            if (isSuccess(response.status)) {
                const inviters = this.constructinvitersTableData(response.data);
                this.setState({ isLoading: false, inviters: inviters });
            } else {
                this.getDialog().status(response.data.message);
            }
        }).catch(e => {
            this.getDialog().status(e.message);
        });
    }
    getDialog = () => {
        return this.confirmElement.current;
    }
    constructinvitersTableData = (inviters) => {
        debugger;
        if (!inviters)
            return [];
        const userObjects = inviters.map(inviter => {
            return {
                inviter: inviter
            };

        });

        return userObjects;
    }

    getInvitersTableColumns = (thisRef) => {
        const columns = [
            {
                name: 'Invited by',
                sortable: true,
                selector: 'inviter'
            }
        ];

        return columns;
    }

    render() {
        return (
            <React.Fragment>
                <PanelTitle panelName="Account access" />
                <div id="updateUsersDiv" style={{ paddingLeft: '10px', paddingTop: '9px' }}>
                    <div id="invite-list-div" style={{ maxWidth: 320 }}>
                        {getDataTable(this.state.inviters, this.columns)}
                    </div>

                </div>
                <Confirm ref={this.confirmElement} show={false} />
            </React.Fragment>

        );
    }
}

AccountAccess.contextType = AuthContext;
export default AccountAccess;