import React from 'react';
import { withRouter } from 'react-router';
import { update } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { waitForLoad } from '../../util/Loader';
import Confirm from '../common/Confirm';
import PropTypes from "prop-types";
import Display from '../../constants/Display';
import SubscriptionDetails from '../../model/SubscriptionDetails';

class ReduceUsers extends React.Component {


    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            rows: null
        }
        this.confirmElement = React.createRef();
    }
    componentDidMount = () => {
        const accData = this.props.accountManagementData();
        const rows = this.prepareTableRowData(accData.seatsToReduce, accData.invitedUsers);
        this.setState({ rows: rows, isLoading: false });
    }

    /*************************Util functions***************************/
    getDialog = () => {
        return this.confirmElement.current;
    }

    goToManageSubscription = () => {
        this.props.history.push(`${this.props.accPath}/manageSubscription`);
    }
    getReduceUserTableRows = () => {
        const tableRows = this.state.rows.map((row, index) => {
            return (<tr key={index}>
                <td>
                    <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        checked={row.include ? 'checked' : null}
                        onChange={e => { this.userCheckboxChanged(e, index) }}
                    />
                </td>
                <td>
                    <select
                        className="form-control form-control-sm"
                        onChange={(e) => { this.userDdlChanged(e, index) }}>
                        {this.getOptionsHtml(row)}
                    </select>
                </td>
            </tr>);
        });

        return tableRows;
    }

    getOptionsHtml = (row) => {
        const optionData = row.optionData;
        const optionHTML = [];
        optionHTML.push(<option value={null}></option>)
        for (let key in optionData) {
            if (optionData[key])
                optionHTML.push(<option selected={key === row.selected ? true : false} value={key}>{key}</option>)
            else
                optionHTML.push(<option style={{ fontStyle: 'italic' }} disabled value={key}>{key}</option>)
        }
        return optionHTML;
    }
    prepareTableRowData = (noOfUsersToReduce, inviteUsers) => {
        if (null == inviteUsers)
            return null;
        const optionData = {};
        inviteUsers.forEach((user, index) => {
            optionData[user] = true;
        });

        const rows = [];
        for (let index = 0; index < noOfUsersToReduce; index++) {
            rows[index] = {
                include: false,
                selectedUser: null,
                optionData: { ...optionData }
            };
        }

        return rows;
    }
    /****************************End of util Fns ****************************/
    /****************************Event handlers ****************************/
    userDdlChanged = (e, index) => {
        const rows = this.state.rows;
        const row = rows[index];
        const previousUser = row.selectedUser;
        const currentUser = e.target.value;
        if (previousUser != currentUser) {
            rows.forEach((r, i) => {
                if (null != previousUser)
                    r.optionData[previousUser] = true;
                if (null != currentUser && i !== index)
                    r.optionData[currentUser] = false;
            });
            row.selectedUser = currentUser;
            this.setState({ rows: [...rows] });
        }



    }

    userCheckboxChanged = (e, index) => {
        const rows = this.state.rows;
        const row = rows[index];
        this.setState({});
        row.include = !row.include;
        this.setState({ rows: [...rows] });
    }

    /****************************End of event handlers**********************/
    updateUsersList = (value) => {
        if (value)
            this.setState({ usersToRemove: this.state.usersToRemove + 1 });
        else
            this.setState({ usersToRemove: this.state.usersToRemove - 1 });
    }

    validateForm = () => {
        debugger;
        const rows = this.state.rows;
        let valid = true;
        let selectedCount = 0;
        rows.forEach(row => {
            if (!row.include || !row.selectedUser) {
                valid = false;
                return false
            }
        });
        if (!valid)
            this.getDialog().status('Select  user/s from the dropdown and check the box to confirm');


        return valid;
    }
    removeUsers = () => {
        const confirm = this.confirmElement.current;
        const accData = this.props.accountManagementData();
        const seatsToReduce = accData.seatsToReduce;
        if (this.validateForm())
            confirm.prompt(`You are suspending access to  ${seatsToReduce} users. Are you sure you want to continue?`, this.reduceUsers);


    }

    reduceUsers = () => {
        const accData = this.props.accountManagementData();
        const uiAccountSubscriptionInfo = accData.uiAccountSubscriptionInfo;
        const noOfSeats = uiAccountSubscriptionInfo.noOfSeats;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        const auth = this.context;
        const revokeUsers = this.state.rows.map(row => {
            if (row.include && row.selectedUser)
                return row.selectedUser;
        });
        debugger;
        const subscriptionDetails = new SubscriptionDetails(
            this.context.user,
            accData.selectedPlan,
            null,
            !isTrial,
            (noOfSeats - accData.seatsToReduce),
            null,
            null,
            null,
            revokeUsers
        );

        this.setState({ isLoading: true });

        update(auth, subscriptionDetails)
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    if (isSuccess(response.status)) {
                        this.getDialog().status('Plan is updated successfully', this.goToManageSubscription);
                    } else {
                        this.getDialog().status(response.data.message);
                    }

                });

            }).
            catch(error => {
                this.setState({ isLoading: false }, () => {
                    this.getDialog().status(error);
                });

            });
    }

    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }
    renderFn = () => {
        const accData = this.props.accountManagementData();

        return (
            <React.Fragment>
                <div id="panel-title">
                    Manage user list
            </div>
                <div style={{ paddingLeft: 10, paddingTop: 9, paddingRight: 10 }}>
                    <div style={{ fontWeight: 'bold' }}>
                        You have reduced the number of users in your plan by {accData.seatReducedBy}. Please select the users for whom you’d like to suspend access. Select a user from the dropdown and check the box to confirm.
                </div>
                    <table id="invite-list-table" style={{ width: '55%' }}>

                        <tbody>
                            {this.getReduceUserTableRows()}

                        </tbody>
                    </table>
                    <div style={{ width: '50%', textAlign: 'center', float: 'none', marginTop: '16px' }}><button className="sq-primary-btn" style={{ float: 'none' }} onClick={this.removeUsers}>Confirm changes</button> </div>
                    <div>
                    </div>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment>

        );
    }
}
ReduceUsers.contextType = AuthContext;
export default withRouter(ReduceUsers);