import React from 'react';
import DataTable from 'react-data-table-component';
import { getHistory, subscription } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import { constructInvoiceTableData, getInvoiceColumns } from '../../util/PaymentHistoryUtil';
import { getDataTable } from '../../util/TableUtil';

class Invoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],

        }

        this.columns = getInvoiceColumns();

    }

    componentDidMount = () => {
        this.fetchPaymentHistory();
    }
    fetchPaymentHistory = () => {
        getHistory(this.context)
            .then(response => {
                const invoices = response.data.invoices;
                this.prepareInvoicesTable(invoices);

            })
            .catch(error => {

            });
    }

    prepareInvoicesTable = (invoices) => {
        const data = constructInvoiceTableData(invoices);
        this.setState({ isLoading: false, data: data });

    }
    render() {
        return getDataTable(this.state.data, this.columns);
    }
}
Invoices.contextType = AuthContext;
export default Invoices;