const Display = {
    STATUS_TRIAL: 'Trial',
    NON_RENEWING: 'NON_RENEWING',
    CANCELLED: 'CANCELLED',
    LINE_ITEM_TYPE_PLAN: 'PLAN'

};

Object.freeze(Display);

export default Display;