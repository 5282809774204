import React from 'react'
import { withRouter } from 'react-router';
import PropTypes from "prop-types";
import PanelTitle from '../common/PanelTitle';
import { waitForLoad } from '../../util/Loader';
import { getSubscription } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AccountManagementData from '../../model/AccountManagentData';
import AuthContext from '../../contexts/AuthContext';

class ConfirmReactivation extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        const acctData = this.props.accountManagementData();
        const uiAccountSubscriptionInfo = acctData ? acctData.uiAccountSubscriptionInfo : null;
        this.state = {
            selectedPlan: uiAccountSubscriptionInfo ? uiAccountSubscriptionInfo.planCode : 'formulasuite_monthly_subscription',
            additionalUsers: uiAccountSubscriptionInfo ? uiAccountSubscriptionInfo.quantity : 0,
            isLoading: false
        }
    }

    componentDidMount = () => {
        const accountManagementData = this.props.accountManagementData();
        if (null == accountManagementData) {
            this.setState({ isLoading: true });
            this.getSubscriptionDetails();
        }

    }
    /*******************************Util functions**********************/
    goToPurchaseSummary = () => {
        const accountManagementData = this.props.accountManagementData();
        accountManagementData.selectedPlan = this.state.selectedPlan;
        accountManagementData.selectedSeats = this.state.additionalUsers;
        this.props.setAccountManagementData({ ...accountManagementData });
        this.props.history.push(`${this.props.accPath}/purchaseSummary`);

    }
    goToManageSubscription = () => {
        const accountManagementData = this.props.accountManagementData();
        if (this.props.match.params.purchase)
            this.props.history.push('/home');
        else
            this.props.history.push(`${this.props.accPath}/manageSubscription`);

    }
    /***************************End of util functions*******************/

    getSubscriptionDetails = () => {
        const { user, tokenId, token } = this.context;
        getSubscription(user, tokenId, token).then(response => {
            if (isSuccess(response.status)) {
                const { uiAccountSubscriptionInfo } = response.data;
                this.setState({
                    isLoading: false
                });
                const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo);
                this.props.setAccountManagementData({ ...accountManagementData });
                const auth = this.context;
                auth.subscriptionDetailResponse = response;
                this.props.setAppState({ ...auth });
            } else {
                this.setState({ isLoading: false });
                this.getDialog().status(response.data.message);
            }

        }).catch(e => {
            this.getDialog().status(e.message);
        });


    }

    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }

    renderFn = () => {
        return (
            <React.Fragment>
                <PanelTitle panelName="Confirm reactivation" />
                <div style={{ paddingLeft: 10, paddingTop: 9, width: '53%' }}>

                    <form className="mt-2 container-fluid" onSubmit={e => e.preventDefault()}>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="name">Select a plan</label>
                            <div className="col-md-8">
                                <select id="planSelector"
                                    className="form-control form-control-sm"
                                    style={{ width: '100%' }}
                                    value={this.state.selectedPlan}
                                    onChange={(e) => { this.setState({ selectedPlan: e.target.value }) }}>
                                    <option value="formulasuite_monthly_subscription">Formulasuite monthly subscription</option>
                                    <option value="formulasuite_annual_subscription">Formulasuite annual subscription</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="email">Additional users </label>
                            <div className="col-md-8">
                                <input id="noOfUsers"
                                    type="number"
                                    min="0"
                                    className="integerField quantity-field"
                                    style={{ border: '1px solid lightgray', color: '#495057' }}
                                    value={this.state.additionalUsers}
                                    onChange={(e) => { this.setState({ additionalUsers: Math.abs(e.target.value) }) }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6"><button type="button" className="btn btn-sm btn-secondary" onClick={this.goToManageSubscription}>Cancel</button></div>
                            <div className="col-sm-6" style={{ textAlign: 'right' }}><button type="button" className="btn btn-sm btn-primary" onClick={this.goToPurchaseSummary}>Next</button></div>
                        </div>
                    </form>
                </div>
            </React.Fragment >
        );
    }
}

ConfirmReactivation.contextType = AuthContext;
export default withRouter(ConfirmReactivation);