class AccountManagementData {
    constructor(uiAccountSubscriptionInfo, selectedPlan, selectedSeats, coupon, amountDue, invitedUsers, seatsToReduce, seatReducedBy) {
        this.uiAccountSubscriptionInfo = uiAccountSubscriptionInfo;
        this.selectedPlan = selectedPlan;
        this.selectedSeats = selectedSeats;
        this.coupon = coupon;
        this.amountDue = amountDue;
        //Properties req for reduce users
        this.invitedUsers = invitedUsers;
        this.seatsToReduce = seatsToReduce;
        this.seatReducedBy = seatReducedBy;
        //
    }
}

export default AccountManagementData;