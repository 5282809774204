import React from 'react';
import { ChangePasswordRequest } from '../../requests/AccountRequests';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import { changePassword } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import { waitForLoad } from '../../util/Loader';
import { addValidateClass } from '../../util/Util';
import AuthContext from '../../contexts/AuthContext';
import { getSessionCookie, setSessionCookie } from '../../util/SessionUtil';
class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.confirmElement = React.createRef();
        this.state = {
            currentPassword: null,
            newPassword: null,
            reEnterPassword: null,
            validateForm: false
        }
    }
    invokePasswordReset = () => {

        if (this.validateResetForm()) {
            const changePasswordReq = ChangePasswordRequest(this.context.user, this.state.currentPassword, this.state.newPassword, this.state.reEnterPassword);

            this.getDialog().prompt('Are you sure you want to reset password?', () => {
                this.setState({ isLoading: true });
                changePassword(this.context, changePasswordReq).then((response) => {
                    if (isSuccess(response.status)) {
                        this.setState({
                            isLoading: false,
                            currentPassword: null,
                            newPassword: null,
                            reEnterPassword: null,
                            validateForm: false
                        });
                        const auth = this.context;
                        if (auth.passwordExpired) {
                            const session = getSessionCookie();
                            auth.passwordExpired = false;
                            session.passwordExpired = false;
                            setSessionCookie(session);
                            this.props.setAppState({ ...auth });
                        }

                    } else {
                        this.getDialog().status(response.data.message);
                        this.setState({ isLoading: false });
                    }
                }).catch(e => {
                    this.setState({ isLoading: false });
                    this.getDialog().status(e.message);
                });
            });

        }
    }
    getDialog = () => {
        return (this.confirmElement.current);
    }
    validateResetForm = () => {
        let isValid = this.state.currentPassword &&
            this.state.newPassword && this.state.reEnterPassword;
        if (!isValid) {
            this.setState({ validateForm: true });
            this.getDialog().status('Please provide required fields');
        } else if (this.state.newPassword != this.state.reEnterPassword) {
            this.setState({ validateForm: true });
            this.getDialog().status('Passwords are not matching. Please re-enter');
            isValid = false;
        }
        else {
            this.setState({ validateForm: false });
        }

        return isValid;
    }

    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }

    renderFn = () => {
        return (
            <React.Fragment>
                <PanelTitle panelName="Change password" />
                <div style={{ paddingLeft: '10px', paddingTop: '9px', width: '70%' }}>
                    {this.props.passwordExpired && <div id="pwdExpiryMsg" className="alert alert-danger">Your password has expired. Please reset your password to continue.</div>}
                    <form className="mt-2 container-fluid" onSubmit={e => { e.preventDefault(); this.invokePasswordReset() }}>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="password">Current password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className={`form-control form-control-sm ${addValidateClass(this.state.currentPassword, this.state.validateForm)}`}
                                    id="password"
                                    value={this.state.currentPassword}
                                    onChange={e => { this.setState({ currentPassword: e.target.value }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="new-password">New password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className={`form-control form-control-sm ${addValidateClass(this.state.newPassword, this.state.validateForm)}`}
                                    id="new-password"
                                    value={this.state.newPassword}
                                    onChange={e => { this.setState({ newPassword: e.target.value }) }}
                                />
                            </div>
                        </div>
                        <div className="form-group row" style={{}}>
                            <label className="col-md-4 col-form-label-sm" htmlFor="re-enter-password">Reenter new password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className={`form-control form-control-sm ${addValidateClass(this.state.reEnterPassword, this.state.validateForm)}`}
                                    id="re-enter-password"
                                    value={this.state.reEnterPassword}
                                    onChange={e => { this.setState({ reEnterPassword: e.target.value }) }}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '-9px' }}>
                            <div className="colmd-12" style={{ color: 'gray', fontStyle: 'italic', paddingLeft: '15px', marginBottom: '10px' }}>
                                Password must be at least 8 characters and include an uppercase letter and a number.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4" />
                            <div className="col-md-6">{this.props.passwordExpired && (<button className="btn btn-secondary btn-sm" onClick={this.props.logoutFn} style={{ marginRight: '60px' }}>Logout</button>)}<button className="btn btn-primary btn-sm" onClick={this.invokePasswordReset}>Reset password</button> </div>
                        </div>
                    </form>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment>
        );
    }
}
ChangePassword.contextType = AuthContext;
export default ChangePassword;