import React from 'react';
export const constructInvoiceTableData = (invoices) => {
    const invoiceObjects = invoices.map(invoice => {
        const createdDate = new Date(invoice.createdAt);
        let createdDateStr = `${createdDate.getDate()}-${createdDate.toLocaleString('default', { month: 'long' })}-${createdDate.getFullYear()}`
        return {

            invoiceNumber: {
                invoiceNumber: invoice.invoiceNumber,
                downloadURI: invoice.downloadURI
            },
            amount: invoice.amount,
            state: invoice.state,
            created: createdDateStr

        };

    });

    return invoiceObjects;
}

export const getInvoiceColumns = () => {
    const columns = [
        {
            name: 'Invoice number',
            sortable: true,
            cell: row => <a target="_blank" href={row.invoiceNumber.downloadURI}>{row.invoiceNumber.invoiceNumber}</a>
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true
        },
        {
            name: 'State',
            selector: 'state',
            sortable: true,
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
        }
    ];

    return columns;
}



export const constructTransactionTableData = (transactions) => {
    const transactionObjects = transactions.map(transaction => {
        const createdDate = new Date(transaction.createdAt);
        let createdDateStr = `${createdDate.getDate()}-${createdDate.toLocaleString('default', { month: 'long' })}-${createdDate.getFullYear()}`
        return {

            type: transaction.action,
            method: transaction.paymentMethod,
            status: transaction.status,
            amount: transaction.amount,
            date: createdDateStr
        };

    });

    return transactionObjects;
}

export const getTransactionColumns = () => {
    const columns = [
        {
            name: 'Type',
            sortable: false,
            selector: 'type',
        },
        {
            name: 'Method',
            selector: 'method',
            sortable: false,
            right: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            right: true,
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
            right: true,
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            right: true,
        }
    ];

    return columns;
}