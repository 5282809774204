import React from 'react';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

class PurchaseSummaryAddon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coupon: '',
            couponDb: '',
            discount: '$0.00',
            amountDue: '$0.00',
            agreeTerms: false,
        }
        this.confirmElement = React.createRef();
    }

    removeCoupon = () => {
        this.setState({ coupon: '', couponDb: '', discount: '$0.00', amountDue: '$199.00' });
    }

    readCoupon = () => {

        if (this.state.coupon !== '5_DIS') {
            this.confirm = this.confirmElement.current;
            this.confirm.status('Invalid coupon code. Please re enter');
            this.removeCoupon();
        }
        else
            this.setState({ couponDb: this.state.coupon, discount: '$5.00', amountDue: '$194.00' });
    }

    goBack = () => {
        this.props.history.goBack();
    }
    proceed = () => {
        this.props.history.push('/billingDetails');
    }
    getCouponRowContent = () => {

        if (this.state.couponDb) {
            return (
                <div id="applied-coupons-div" >
                    <label id="discount-code-label">Discount code</label>
                    <span className="label label-primary border-black" style={{ marginRight: '8px', marginLeft: '4px' }} >{this.state.couponDb}</span>
                    <button id="remove-coupon" type="button" onClick={() => this.removeCoupon()} className="btn btn-sm btn-primary">Remove</button>
                </div>
            );

        } else {
            return (
                <div id="apply-coupon-div">
                    <form className="form-inline">
                        <label id="discount-code-label">Discount code</label>
                        <input type="text"
                            id="coupon"
                            className="form-control form-control-sm"
                            style={{ marginLeft: '9px' }}
                            autoComplete="off" value={this.state.coupon}
                            onChange={(e) => { this.setState({ coupon: e.target.value }) }} />
                        <button type="button"
                            id="apply-coupon"
                            onClick={() => { this.readCoupon() }}
                            style={{ marginLeft: '9px', fontFamily: 'calibri', fontSize: '13px', height: '25px', paddingTop: '2px' }}
                            className="btn btn-sm btn-primary">Apply
                                        </button>
                    </form>
                </div>
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                <PanelTitle panelName="Purchase summary" />
                <div id="purchaseSummaryDiv">
                    <table id="purchaseSummaryTable">
                        <thead>
                            <tr style={{ borderBottom: '1px solid lightgray' }}>
                                <th>Selected plan</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ height: '26px' }}>
                                <td>Additional users (Selected: 1 users)</td>
                                <td>$199.00</td>
                            </tr>
                            <tr className="separator-row">
                                <td colSpan={2} />
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>$199</td>
                            </tr>
                            <tr>
                                <td>Credit</td>
                                <td>$0.00</td>
                            </tr>
                            <tr id="discountRow" className="summation-row" >
                                <td style={{ textAlign: 'right', paddingRight: '18px' }}>
                                    {this.getCouponRowContent()}
                                </td>
                                <td id="discounts">{this.state.discount}</td>
                            </tr>
                            <tr>
                                <td>Amount due</td>
                                <td>{this.state.amountDue}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div id="sq-agreement-div" style={{ borderTop: '1px solid', width: '98%', paddingTop: '2px' }} className="mt-3">
                        <label style={{ fontWeight: 'normal', fontFamily: 'calibri' }}><input type="checkbox" id="agree-terms" value={this.state.agreeTerms} onChange={(e) => { this.setState({ agreeTerms: e.target.value }) }} /> By
    checking this box, you agree to Seqnc Inc.'s <a href="TermsOfService" target="_blank">Terms of Service</a> and <a href="PrivacyPolicy" target="_blank">Privacy Policy</a></label>
                    </div>
                    <div id="navgationBtnsDiv"><button className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={this.goBack}>Back</button>
                        <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={this.proceed}>Next:Payment details</button>
                    </div>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment >
        );
    }
}

export default PurchaseSummaryAddon;
