import React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class HomeInvited extends React.Component {

    render() {
        return (
            <div>


                <Alert variant="primary">
                    Your account is associated as a guest or additional paid user for another account, and does not have Admin privileges.<p><Link to="/accountAccess" className="blueLink" style={{ textDecoration: 'underline' }}>Click here to view account associations.</Link>
                    (Only available options in the dashboard are enabled.) You can set up your own account with full Admin access and ability to add guest and additional paid users. <Link className="btn btn-primary btn-sm" to="/home">Start free trial</Link></p>

                </Alert>

            </div>

        );
    }
}

export default HomeInvited;

