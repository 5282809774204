import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import PanelTitle from '../common/PanelTitle';
import BillingDetails from '../subscriptions/BillingDetails';
import Confirmations from './Confirmations';
import Invoices from './Invoices';



class BillingsAndInvoices extends React.Component {
    constructor(props) {
        super(props);
        const { accPath, setAccountManagementData, accountManagementData } = props;

    }

    render() {

        return (
            <React.Fragment>
                <PanelTitle panelName="Update billings/View transactions" />
                <div style={{ paddingLeft: 10, paddingTop: 9, paddingRight: 10 }}>
                    <Tabs defaultActiveKey="updateBillings" id="manage-users-tab">
                        <Tab eventKey="updateBillings" title="Update billings">
                            <BillingDetails
                                updateBilling={true}
                                accPath={this.props.accPath}
                                setAccountManagementData={this.props.setAccountManagementData}
                                accountManagementData={this.props.accountManagementData}
                            />
                        </Tab>
                        <Tab eventKey="invoices" title="Invoices">
                            <Invoices />
                        </Tab>
                        <Tab eventKey="paymentConfirmations" title="Payment confirmations">
                            <Confirmations />
                        </Tab>
                    </Tabs>
                </div>
            </React.Fragment>
        );
    }
}

export default BillingsAndInvoices;