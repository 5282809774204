import React from 'react';
import PanelTitle from '../common/PanelTitle';
import { cancelSubscription } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import Confirm from '../common/Confirm';
import { isSuccess } from '../../constants/Status';
import { getSubscriptionEndDate } from '../../util/Util';
import { withRouter } from 'react-router';
import PropTypes from "prop-types";

class ConfirmCancellation extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {

        super(props);
        const { uiAccountSubscriptionInfo } = this.props.accountManagementData();
        this.state = {
            subscriptionEndDate: getSubscriptionEndDate(uiAccountSubscriptionInfo),
            isLoading: false,
            confirmEndDate: false
        }

        this.confirmElement = React.createRef();

    }


    getDialog = () => {
        return this.confirmElement.current;
    }
    invokeCancelSubscription = () => {
        if (this.state.confirmEndDate) {
            cancelSubscription(this.context).then((response) => {
                if (isSuccess(response.status)) {
                    this.props.history.push(`${this.props.accPath}/manageSubscription`);
                }
            });
        }
        else
            this.getDialog().status('Please select the checkbox');
    }
    goToDashboard = () => {
        this.props.history.push('/home');
    }
    render() {
        return (
            <React.Fragment>
                <PanelTitle panelName="Confirm cancellation" />
                <div style={{ paddingLeft: '10px', paddingTop: '9px' }}>
                    <div className="subscription-cancel-details" style={{ textAlign: 'center' }}>
                        <div className="cancel-subscription-div full-width" style={{ width: '656px', paddingLeft: '41px' }}>
                            <input
                                id="cancel-subscription-checkbox"
                                type="checkbox"
                                style={{ marginRight: '3px' }}
                                checked={this.state.confirmEndDate ? 'checked' : null}
                                onChange={(e) => { this.setState({ confirmEndDate: e.target.value }) }}
                            />
                            <span className style={{ fontWeight: 'normal' }}>{`Cancel my subscription renewal on ${this.state.subscriptionEndDate} for all products and services on this account`}</span></div>
                        <div className="cancel-subscription-btn-div full-width" style={{ paddingLeft: '74px', paddingRight: '199px', marginTop: '25px' }}>
                            <button
                                className="btn btn-sm btn-primary"
                                onClick={this.goToDashboard} style={{ float: 'left' }}>Keep using FormulaSuite</button>
                            <button
                                className="btn btn-sm btn-secondary"
                                data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Cancel my subscription"
                                style={{ float: 'right' }} onClick={this.invokeCancelSubscription}>Cancel my subscription</button>
                        </div>
                    </div>
                </div>
                <Confirm ref={this.confirmElement} show={false} />
            </React.Fragment>

        );
    }
}
ConfirmCancellation.contextType = AuthContext;
export default withRouter(ConfirmCancellation);
