import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inviteUser, invokeOTP } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import { OTPRequest } from '../../requests/AccountRequests';
import { ChangeOwnerRequest } from '../../requests/SubscriptionRequests';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import AuthContext from '../../contexts/AuthContext';
import { changeOwner } from '../../api/subscription';
import { waitForLoad } from '../../util/Loader';
import PropTypes from "prop-types";
import { isValidEmail } from '../../util/Util';

class ChangeAccountOwner extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.confirmElement = React.createRef();
        this.state = {
            password: null,
            newOwnerEmail: null,
            newOwnerEmail1: null,
            otp: null,
            isLoading: false
        };
    }

    getDialog = () => {
        return this.confirmElement.current;
    }
    goToDashboard = () => {
        this.props.history.push('/home');
    }

    transferAccount = () => {

        if (this.validate(true)) {
            this.getDialog().prompt('You are transferring your subscription. You will loose access to FormulaSuite post transfer.', () => {
                const request = ChangeOwnerRequest(this.state.newOwnerEmail, this.context.user, this.state.otp, this.state.password);
                this.setState({ isLoading: true });
                changeOwner(this.context, request).then((response) => {
                    this.setState({ isLoading: false }, () => {
                        debugger;
                        if (isSuccess(response.status)) {
                            const auth = this.context;
                            auth.subscriptionDetailResponse = null;
                            this.props.setAppState({ ...auth });
                            this.getDialog().status('Account transferred successfully', this.goToDashboard);
                        }
                        else
                            this.getDialog().status(response.data.message);
                    });

                }).catch(e => {
                    this.setState({ isLoading: false }, () => {
                        this.getDialog().status(e.message);
                    })
                })
            });

        }




    }

    validate = (transferAccount) => {
        let valid = this.state.newOwnerEmail === this.state.newOwnerEmail1;
        if (!valid)
            this.getDialog().status("Email and confirm Email are not matching.Pleas re-enter");
        if (!isValidEmail(this.state.newOwnerEmail)) {
            valid = false;
            this.getDialog().status("Please enter a valid email address");
        }
        if (transferAccount && !this.state.otp) {
            this.getDialog().status("Please enter OTP");
            valid = false;
        }
        return valid;
    }
    sendOtp = () => {
        if (this.validate(false)) {
            this.getDialog().prompt('Are you sure you want to send OTP?', () => {
                this.setState({ isLoading: true });
                invokeOTP(this.context, OTPRequest(true, this.context.user, this.state.newOwnerEmail)).then((response) => {
                    this.setState({ isLoading: false }, () => {
                        if (isSuccess(response.status)) {
                            this.getDialog().status('OTP sent successfully');
                        }
                    });

                }).catch(e => {
                    this.getDialog().status(e.message);
                });
            });
        }



    }


    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }
    renderFn = () => {
        return (
            <React.Fragment>
                <PanelTitle panelName="Change account owner" />
                <div id="updateUsersDiv" style={{ paddingLeft: 10, paddingTop: 9, width: '82%' }}>

                    <form className="mt-2 container-fluid" id="change-owner-form" onSubmit={e => e.preventDefault()}>
                        <div className="form-group row">
                            <label className="col-md-6 col-form-label-sm required-label" htmlFor="name">Enter your current password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className="form-control form-control-sm"
                                    id="password"
                                    value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-6 col-form-label-sm required-label" htmlFor="email">Enter email address of new owner </label>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control form-control-sm "
                                    id="email"
                                    value={this.state.newOwnerEmail}
                                    onChange={e => this.setState({ newOwnerEmail: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-6 col-form-label-sm required-label" htmlFor="email">Reenter email address of new owner</label>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="email1"
                                    value={this.state.newOwnerEmail1}
                                    onChange={e => this.setState({ newOwnerEmail1: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-6 col-form-label-sm required-label" htmlFor="industry">Enter one-time password (OTP)</label>
                            <div className="col-md-4">
                                <button className="btn btn-success btn-sm" onClick={this.sendOtp}>Send OTP to new owner</button>
                            </div>
                            <div className="col-md-2">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="otp"
                                    value={this.state.otp}
                                    onChange={e => this.setState({ otp: e.target.value })}
                                />
                            </div>

                        </div>
                        <div className="field-note">Note: The OTP will be sent to the new_owner email address. Please request and enter here for validation.</div>
                    </form>
                    <div style={{ paddingLeft: 15, paddingRight: 15, marginTop: '13px' }}><Link to="/home" className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={this.goToDashboard}>Canel</Link><button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={this.transferAccount}>Next</button> </div>
                </div >
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment >

        );
    }
}
ChangeAccountOwner.contextType = AuthContext;
export default withRouter(ChangeAccountOwner);