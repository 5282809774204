export const env = {
    //local
   // url: 'http://localhost:8766',
    //test
    // url: 'https://tfsg.seqnc.com',
    //production
     url: 'https://gateway.formulasuite.com',
    USD: '$'
}

Object.freeze(env);
