import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.show ? props.show : false,
            body: props.body ? props.body : '',
            okFunction: props.okFunction ? props.okFunction : null,
            cancelFunction: props.cancelFunction ? props.cancelFunction : null,
            okLabel: props.okLabel ? props.okLabel : 'OK',
            cancelLabel: props.cancelLabel ? props.cancelLabel : 'Cancel',
            title: props.title ? props.title : null,
            backdrop: props.backdrop ? props.backdrop : 'static',
            keyboard: props.keyboard,
            showCancel: props.showCancel
        }
        this.modalHeader = '';
        this.setModalHeader();
        this.okRef = React.createRef();
        this.cancelRef = React.createRef();
    }
    handleClose = () => {
        if (this.state.cancelFunction)
            this.state.cancelFunction();
        this.setState({ show: false });
    };

    handleOK = () => {
        if (this.state.okFunction)
            this.state.okFunction();
        this.setState({ show: false });
    };

    prompt = (body, okFunction, cancelFunction, okLabel, cancelLabel, backdrop, title, keyboard) => {
        this.callSetState(body, okFunction, cancelFunction, 'Yes', cancelLabel, title, backdrop, keyboard, true, () => {
            this.okRef.current.focus();
        });
    }

    status = (body, okFunction, cancelFunction, okLabel, cancelLabel, backdrop, title, keyboard) => {
        this.callSetState(body, okFunction, cancelFunction, okLabel, cancelLabel, title, backdrop, keyboard, false, () => {
            this.okRef.current.focus();
        });

    }


    callSetState = (body, okFunction, cancelFunction, okLabel, cancelLabel, title, backdrop, keyboard, showCancel, callBack) => {
        this.setState({
            show: true,
            showCancel: showCancel,
            body: body ? body : '',
            okFunction: okFunction ? okFunction : null,
            cancelFunction: cancelFunction ? cancelFunction : null,
            okLabel: okLabel ? okLabel : 'OK',
            cancelLabel: cancelLabel ? cancelLabel : 'Cancel',
            title: title ? title : null,
            backdrop: backdrop ? backdrop : 'static',
            keyboard: keyboard
        }, () => {
            callBack();
        });
        this.setModalHeader();
    }
    setModalHeader = () => {
        this.modalHeader = (this.state.title) ? (

            <Modal.Header >
                <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
        ) : ''
    }


    render() {

        return (

            <Modal className="seqnc-modal"
                show={this.state.show}
                onHide={this.handleClose}
                backdrop={this.state.backdrop}
                keyboard={this.state.keyboard}

            >
                {this.modalHeader}

                <Modal.Body dangerouslySetInnerHTML={{ __html: this.state.body }}>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.showCancel ? (
                        <Button ref={this.cancelRef}
                            variant="secondary"
                            size="sm"
                            onClick={this.handleClose}
                            show={this.state.showCancel}>
                            {this.state.cancelLabel}
                        </Button>) : ''}
                    <Button
                        ref={this.okRef}
                        variant="primary"
                        size="sm"
                        onClick={this.handleOK}>{this.state.okLabel}</Button>
                </Modal.Footer>
            </Modal >
        );
    }


}

export default Confirm;