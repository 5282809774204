import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import fsLogo from '../../images/fs-logo.svg';
import { resetPassword } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import { addValidateClass, isValidPassword } from '../../util/Util';
import Confirm from '../common/Confirm';
import PropTypes from "prop-types";
import querystring from 'query-string';
import { waitForLoad } from '../../util/Loader';
import { Messages } from '../../constants/Messages';

class ResetPassword extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            validateForm: false,
            isLoading: false
        };
        this.confirmElement = React.createRef();
    }
    componentDidMount = () => {
        const query = querystring.parse(this.props.location.search, { decode: true });
        const email = query.email;
        const resetToken = query.token;
        if (!email || !resetToken) {
            this.getDialog().status('This is an invalid reset link.', () => {
                this.props.history.push('/');
            });
        }
    }

    validateResetForm = () => {
        let isValid = this.state.password && this.state.confirmPassword && this.state.password === this.state.confirmPassword;
        if (this.state.password !== this.state.confirmPassword) {
            this.getDialog().status('Password is not matching. Please re-enter');
            isValid = false;
        }
        if (!isValidPassword(this.state.password)) {
            this.getDialog().status(Messages.INVALID_PASSWORD_MSG);
            isValid = false;
        }
        return isValid;
    }
    getDialog = () => {
        return this.confirmElement.current;
    }

    ResetPasswordRequest = (emailAddress, password, confirmPassword, resetToken) => {
        const resetPasswordRequest = {};
        resetPasswordRequest.emailAddress = emailAddress;
        resetPasswordRequest.password = password;
        resetPasswordRequest.confirmPassword = confirmPassword;
        resetPasswordRequest.resetToken = resetToken;
        return resetPasswordRequest;

    }
    invokeResetPassword = () => {


        if (this.validateResetForm()) {
            this.getDialog().prompt('Are you sure you want to reset your password?', () => {
                this.setState({ validateForm: false, isLoading: true });
                const query = querystring.parse(this.props.location.search, { decode: true });
                const email = query.email;
                const resetToken = query.token;
                resetPassword(this.ResetPasswordRequest(email, this.state.password, this.state.confirmPassword, resetToken))
                    .then((response) => {
                        if (isSuccess(response.status)) {
                            this.setState({ isLoading: false }, () => {
                                this.getDialog().status('You have successfully reset the password.', () => {
                                    this.props.history.push('/');
                                });
                            });
                        }
                        else {
                            this.setState({ isLoading: false }, () => {
                                if (response.data.message)
                                    this.getDialog().status(response.data.message);
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log('fs error', error);
                    });
            });
        } else {
            this.setState({ validateForm: true });
        }


    }

    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }
    renderFn = () => {
        return (
            <React.Fragment>
                <div id="loginDiv">
                    <div className="mb-4"><img src={fsLogo} style={{ height: '108px', width: '268px', marginTop: '-21px', marginBottom: '-29px' }} /></div>
                    <form className="container-fluid" onSubmit={(e) => { e.preventDefault(); this.invokeResetPassword() }}>

                        <div class="form-group">
                            <label className="required-label">Enter password</label>
                            <input
                                type="password"
                                id="password"
                                className={`form-control form-control-sm ${addValidateClass(this.state.password, this.state.validateForm)}`}
                                value={this.state.password}
                                onChange={(e) => { this.setState({ password: e.target.value }); }} />
                        </div>
                        <div class="form-group">
                            <label className="required-label">Reenter password</label>
                            <input
                                type="password"
                                id="reEnterPassword"
                                className={`form-control form-control-sm ${addValidateClass(this.state.confirmPassword, this.state.validateForm)}`}
                                value={this.state.confirmPassword} onChange={(e) => { this.setState({ confirmPassword: e.target.value }); }} />
                        </div>
                        <div className="field-note" style={{ marginTop: 11 }}>Password must be at least 8 characters and include an uppercase letter and a number.</div>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <button className="btn btn-primary btn-sm" style={{ fontWeight: 'bold' }} onClick={this.invokeResetPassword}>Set password</button>
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            <Link to="/" className="bluelink ml-2" ><strong>Login</strong></Link>
                        </div>
                    </form>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment>
        );

    }
}

export default withRouter(ResetPassword);