import React from 'react';
import { Link } from 'react-router-dom';
import fsLogo from '../../images/fs-logo.svg';
import { AddinLoginRequest, AddinSignUpRequest } from '../../requests/AccountRequests';
import Auth from '../../model/Auth';
import { login, signup } from '../../api/account';
import { getSubscription } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import Confirm from '../common/Confirm';
import { addValidateClass, isValidEmail, isValidPassword } from '../../util/Util';
import Session from '../../model/Session';
import { setSessionCookie } from '../../util/SessionUtil';
import { waitForLoad } from '../../util/Loader';
import { Messages } from '../../constants/Messages';

class Signup extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            email: '',
            password: '',
            resetPassword: '',
            confirmPassword: '',
            validateForm: false,
            isLoading: false
        };

        this.confirmElement = React.createRef();
    }
    getDialog = () => {
        return this.confirmElement.current;
    }
    validateSignupForm = () => {
        const { email, password, confirmPassword } = this.state;

        if (!email || !password || !confirmPassword) {
            this.setState({ validateForm: true });
            return false;
        }
        if (!isValidEmail(email)) {
            this.getDialog().status('Please enter a valid email address');
            return false;
        }
        if (!isValidPassword(password)) {
            this.getDialog().status(Messages.INVALID_PASSWORD_MSG);
            return false;
        }
        if (password != confirmPassword) {
            this.getDialog().status('Passwords are not matching. Please re-enter');
            return false;
        }
        return true;

    }
    signupUser = async () => {
        console.log('signupUser');
        const { email, password } = this.state;
        if (!this.validateSignupForm())
            return;
        this.setState({ isLoading: true });
        signup(AddinSignUpRequest(email, password))
            .then((response) => {
                if (isSuccess(response.status)) {
                    let addinLoginRequest = AddinLoginRequest(email, password, null);
                    login(addinLoginRequest)
                        .then(response => {
                            const { token, token_id } = response.headers;
                            const isPasswordExpired = response.data.isPasswordExpired;
                            const auth = new Auth(email, token_id, token);
                            const session = Session.getInstance(auth);
                            setSessionCookie(session);
                            getSubscription(email, token_id, token).then(subscriptionDetailResponse => {
                                if (isSuccess(subscriptionDetailResponse.status))
                                    auth.subscriptionDetailResponse = subscriptionDetailResponse;
                                else
                                    this.getDialog().status(subscriptionDetailResponse.data.message);
                                this.setState({ isLoading: false });
                                this.props.setAppState(auth);
                            });

                        })
                        .catch(function (error) {
                            this.setState({ isLoading: false });
                            console.log('fs error', error);
                        });

                } else {
                    this.setState({ isLoading: false }, () => {
                        this.getDialog().status(response.data.message);
                    });
                }

            })
            .catch(function (error) {
                this.setState({ isLoading: false });
                console.log('fs error', error);
            });
        ;
    }
    renderFn = () => {
        return (
            <React.Fragment>
                <div id="loginDiv" style={{ height: 442 }}>
                    <div className="mb-4"><img src={fsLogo} style={{ height: '108px', width: '268px', marginTop: '-21px', marginBottom: '-29px' }} /></div>
                    <form className="container-fluid" onSubmit={(e) => { e.preventDefault(); }}>
                        <div className="form-group">
                            <label className="required-label">Enter your email</label>
                            <input
                                type="email"
                                id="email"
                                style={{ textTransform: 'lowercase' }}
                                className={`form-control form-control-sm ${addValidateClass(this.state.email, this.state.validateForm)}`}
                                value={this.state.email}
                                onChange={(e) => { this.setState({ email: e.target.value }); }}
                            />
                        </div>
                        <div class="form-group">
                            <label className="required-label">Enter password</label>
                            <input
                                type="password"
                                id="password"
                                className={`form-control form-control-sm ${addValidateClass(this.state.password, this.state.validateForm)}`}
                                value={this.state.password}
                                onChange={(e) => { this.setState({ password: e.target.value }); }} />
                        </div>
                        <div class="form-group">
                            <label className="required-label">Reenter password</label>
                            <input
                                type="password"
                                id="reEnterPassword"
                                className={`form-control form-control-sm ${addValidateClass(this.state.confirmPassword, this.state.validateForm)}`}
                                value={this.state.confirmPassword} onChange={(e) => { this.setState({ confirmPassword: e.target.value }); }} />
                        </div>
                        <div className="field-note" style={{ marginTop: 11 }}>Password must be at least 8 characters and include an uppercase letter and a number.</div>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <button class="btn btn-primary btn-sm" style={{ fontWeight: 'bold' }} onClick={this.signupUser}>Start 14-day Trial</button>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ borderTop: '1px solid lightgray', fontWeight: 'bold', color: '#6e6d6d' }}>Already have an account?</span>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Link to="/" className="bluelink ml-2" ><strong>Login</strong></Link>
                        </div>
                    </form>
                </div>
                <Confirm show={false} ref={this.confirmElement} />
            </React.Fragment>
        );

    }

    render = () => {
        return waitForLoad(this.state.isLoading, this.renderFn);
    }
}

export default Signup;