import React from 'react';
import DataTable from 'react-data-table-component';
import { getHistory } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import { constructTransactionTableData, getTransactionColumns } from '../../util/PaymentHistoryUtil';
class Confirmations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],

        }
        this.columns = getTransactionColumns();
    }

    componentDidMount = () => {
        this.fetchPaymentHistory();
    }
    fetchPaymentHistory = () => {
        getHistory(this.context)
            .then(response => {
                const transactions = response.data.transactions;
                this.prepareTransactionsTable(transactions);

            })
            .catch(error => {

            });
    }

    prepareTransactionsTable = (transactions) => {
        const data = constructTransactionTableData(transactions);
        this.setState({ isLoading: false, data: data });

    }
    render() {
        return (
            <DataTable title={null} striped={true} dense columns={this.columns} data={this.state.data} />

        );
    }
}
Confirmations.contextType = AuthContext;
export default Confirmations;